import React from "react";
import "./Home.css";
import "./UserManagement.css";
import { iconsIndex } from "../icons/icons_index";
import DataTbl from "../components/DataTbl";
import { useState, useEffect } from "react";
import ExpandSearch from "../components/ExpandSearch";
import ActionModal from "../components/ActionModal";
import MenuBar from "../components/MenuBar";
import ProjectInfo from "../components/ProjectInfo";
import { menubarData } from "../data/menubar_data";
import Survey from "../components/Survey";
import { checklistData } from "../data/checklist_data.js";
import toastNotify from "../components/Toast.js";
import { ToastContainer, toast } from "react-toastify";
import CreateUserModal from "../components/CreateUserModal";
import { RotatingLines } from "react-loader-spinner";
import { fetchData, saveByteArray } from "../Utils";
import { useUserAuth } from "../hooks/useUserAuth.js";
import { useNavigate } from "react-router-dom";
import { Button } from "@appkit4/react-components";

export default function DealManagementPage() {
  // User Data table styling
  const customStyles = {
    headCells: {
      style: {
        color: "#ffffff",
        backgroundColor: "#203570",
        border: "0px solid #fff",
        display: "flex",
        alignItems: "left !important",
        justifyContent: "left !important",
        paddingLeft: "8px !important",
        width: "70%",
      },
    },
    headRow: {
      style: {
        borderRadius: "3px",
        fontSize: "14px",
        width: "100%",
      },
    },
    rows: {
      style: {
        fontSize: "12px",
        fontWeight: 500,
        margin: "4px",
        borderBottom: "2px solid #F0F0F0",
        //alignItems: "center",
        justifyContent: "left",

        // color: theme.text.primary,
        // backgroundColor: '#656565',
        minHeight: "30px",
        "&:not(:last-of-type)": {
          borderBottomStyle: "2px solid #F0F0F0",
          // borderBottomWidth: '1px',
          // borderBottomColor: theme.divider.default,
        },
      },
      denseStyle: {
        minHeight: "20px",
      },
    },
    expanderButton: {
      style: {
        color: "#FFFFFF",
        fill: "#FFFFFF",
        backgroundColor: "#000000",
        borderRadius: "2px",
        transition: "0.25s",
        height: "100%",
        width: "100%",
        "&:hover:enabled": {
          cursor: "pointer",
        },
        "&:disabled": {
          color: "#A0A0A0",
        },
        "&:hover:not(:disabled)": {
          cursor: "pointer",
          backgroundColor: "#A0A0A0",
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "#FFFFFF",
        },
        svg: {
          margin: "auto",
        },
        "@global": {
          "*::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
          },

          "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": " inset 0 0 2px rgba(0,0,0,0.3)",
            borderRadius: "10px",
          },
          "*::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            "-webkit-box-shadow": "inset 0 0 2px rgba(0,0,0,0.5)",
            backgroundColor: "#909090",
          },
        },
      },
    },
  };

  const handleButtonClick = (e, row) => {
    e.preventDefault();
    getDealDetails(row.deal_id);
  };
  const columns = [
    {
      name: "Project Name",
      selector: (row) => row.project_name,
      sortable: true,
      button: true,
      width: "275px",
      headerStyle: (selector, id) => {
        return { textAlign: "left" };
      },
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
      },
      cell: (row) => (
        <button
          className={
            row.client_description == "*CONFIDENTIAL*" ||
            row.target_description == "*CONFIDENTIAL*"
              ? "btn-disable"
              : "username-button"
          }
          disabled={
            row.client_description == "*CONFIDENTIAL*" ||
            row.target_description == "*CONFIDENTIAL*"
          }
          onClick={(e) => handleButtonClick(e, row)}
        >
          {row.project_name}
        </button>
      ),
    },
    {
      name: "Engagement Leader",
      selector: (row) => row.engagement_leader,
      sortable: true,
      width: "250px",
    },
    {
      name: "Engagement Manager",
      selector: (row) => row.engagement_manager,
      sortable: true,
      width: "280px",
    },
    {
      name: "Client Business",
      selector: (row) => row.client_description,
      sortable: true,
      width: "280px",
    },
    {
      name: "Target Business",
      selector: (row) => row.target_description,
      sortable: true,
      width: "275px",
    },
    {
      name: "Created By",
      selector: (row) => row.created_by,
      sortable: true,
      width: "250px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      format: (row) =>
        Number(row.status) <= 1 ? "Survey Pending" : "Checklist Generated",
      sortable: true,
      width: "150px",
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      },
      conditionalCellStyles: [
        {
          when: (row) => Number(row.status) <= 1,
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#EED4D1",
            borderRadius: "15px",
            width: "100px",
            marginBottom: "5px",
          },
        },
        {
          when: (row) => Number(row.status) === 2,
          style: {
            backgroundColor: "#D7EBD4",
            borderRadius: "15px",
            margin: "5px",
            padding: "5px",
          },
        },
      ],
    },
  ];

  const navigate = useNavigate();
  // Set Search state
  const { user, setUser } = useUserAuth();
  const [deals_data, setDealsData] = useState([]);
  const [dealsFetched, setDealsFetched] = useState(false);
  const [filteredDealData, setFilteredDealData] = useState(deals_data);
  const [users_data, setUsersData] = useState([]);

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);

  // Routing params
  const [dealDisplayState, setDealDisplayState] = useState("deal_management");
  const [showLoader, setShowLoader] = useState(false);

  // State for managing checklist deal id
  const [deal_id, setDealId] = useState(null);
  const [deal_name, setDealName] = useState("");

  // Form params for new deal
  const [dealProjectDetails, setDealProjectDetails] = useState({
    project_name: "",
    created_by: user.user_id,
    created_on: Date.now(),
    qrp_details: "",
    engagement_leader: "",
    engagement_manager: "",
    team_members: [],
    client_name: "",
    client_description: "",
    target_name: "",
    target_description: "",
    transaction_description: "",
    scope_of_work_desc: "",
    status: 0,
    confedential: "No",
  });
  const [showSearchUsers, setshowSearchUsers] = useState({
    project_name: false,
    created_by: false,
    qrp_details: false,
    engagement_leader: false,
    engagement_manager: false,
    client_name: false,
    target_name: false,
  });

  // Form params for existing deal
  const [dealProjectDetailsExisting, setDealProjectDetailsExisting] = useState({
    project_name: "",
    created_by: "",
    created_on: Date.now(),
    qrp_details: "",
    engagement_leader: "",
    engagement_manager: "",
    team_members: [],
    client_name: "",
    client_description: "",
    target_name: "",
    target_description: "",
    transaction_description: "",
    scope_of_work_desc: "",
    status: 1,
    confedential: "No",
  });
  const [showSearchUsersExisting, setshowSearchUsersExisting] = useState({
    project_name: false,
    created_by: false,
    qrp_details: false,
    engagement_leader: false,
    engagement_manager: false,
    client_name: false,
    target_name: false,
  });

  const userFetchCallback = (data) => {
    if (data != null) {
      setUsersData(data.user);
      setShowLoader(false);
    } else {
      setUsersData([]);
      setShowLoader(false);
      toastNotify("Users update failed!", "error");
    }
  };

  const dealFetchCallback = (data) => {
    if (data != null) {
      setDealsData(data.data);
      setFilteredDealData(data.data);
      setDealsFetched(true);
      setShowLoader(false);
    } else {
      setDealsData([]);
      setFilteredDealData([]);
      setDealsFetched(true);
      setShowLoader(false);
      toastNotify("Users update failed!", "error");
    }
  };

  // hp - checking the button is disabled or not
  const checkIsDisabled = (e) => {
    if (dealProjectDetailsExisting.project_name.length) {
      if (!dealProjectDetailsExisting.project_name.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetailsExisting.created_by.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetailsExisting.engagement_leader.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetailsExisting.engagement_manager.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetailsExisting.team_members.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetailsExisting.client_name.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetailsExisting.client_description.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetailsExisting.target_name.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetailsExisting.target_description.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetailsExisting.transaction_description.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetailsExisting.scope_of_work_desc.length) {
        setIsSaveBtnDisabled(true);
      } else {
        setIsSaveBtnDisabled(false);
      }
    } else {
      if (!dealProjectDetails.project_name.length) {
        setIsSaveBtnDisabled(true);
        // } else if(!dealProjectDetails.created_by.length) {
        //   setIsSaveBtnDisabled(true)
      } else if (!dealProjectDetails.engagement_leader.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetails.engagement_manager.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetails.team_members.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetails.client_name.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetails.client_description.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetails.target_name.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetails.target_description.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetails.transaction_description.length) {
        setIsSaveBtnDisabled(true);
      } else if (!dealProjectDetails.scope_of_work_desc.length) {
        setIsSaveBtnDisabled(true);
      } else {
        setIsSaveBtnDisabled(false);
      }
    }
  };

  useEffect(() => {
    setShowLoader(true);
    // setIsSaveBtnDisabled(true);
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234", //user.user_access_token to be added here.
      User_Email: user.user_id,
    };
    var paylod = {
      User_Email: user.user_id,
    };
    // show damroo here. Loader.
    var deal_response = fetchData(
      "get_all_deals",
      paylod,
      "POST",
      headers,
      dealFetchCallback
    );
    // var user_response = fetchData(
    //   "get_all_registered_users",
    //   {},
    //   "POST",
    //   headers,
    //   userFetchCallback
    // );
  }, [dealDisplayState]);

  useEffect(() => {
    checkIsDisabled();
  }, [dealProjectDetails, dealProjectDetailsExisting]);

  const filterDealData = (search_query) => {
    if (search_query.length === 0 || search_query.length === null) {
      setFilteredDealData(deals_data);
    } else {
      const search_string = search_query.toLowerCase();
      const search_results = deals_data.filter(function (deal) {
        return deal.project_name.toLowerCase().includes(search_string);
      });
      setFilteredDealData(search_results);
    }
  };

  // Params for user search data
  const user_search_data = users_data.map((item) => {
    return item.user_name + " - " + item.user_id;
  });

  const engagement_leader_search_data = users_data
    .map((item) => {
      if (item.designation.includes("Partner")) {
        // return {value: item.user_name, label: item.user_name }
        return {
          value: item.user_name + " - " + item.user_id,
          label: item.user_name + " - " + item.user_id,
        };
      }
    })
    .filter((notUndefined) => notUndefined !== undefined);

  const engagement_manager_search_data = users_data
    .map((item) => {
      if (
        item.designation.includes("Manager") ||
        item.designation.includes("Senior Manager") ||
        item.designation.includes("Director") ||
        item.designation.includes("Managing Director")
      ) {
        return {
          value: item.user_name + " - " + item.user_id,
          label: item.user_name + " - " + item.user_id,
        };
      }
    })
    .filter((notUndefined) => notUndefined !== undefined);

  //   const engagement_manager_search_data = users_data.map((item) => {
  //     if (!item.designation.includes("Partner")) {
  //         return (item.user_name + " - " + item.user_id)
  //     }
  // }).filter(notUndefined => notUndefined !== undefined);

  const user_select_data = users_data.map((item) => {
    return {
      value: item.user_name + " - " + item.user_id,
      label: item.user_name + " - " + item.user_id,
    };
    //item.designation.includes("Partner");
  });

  // Define State Management functions
  const handleInputFieldChange = (val, val_field) => {
    debugger;
    if (dealDisplayState === "create_deal") {
      setshowSearchUsers((previousState) => {
        return { ...previousState, [val_field]: true };
      });

      setDealProjectDetails((previousState) => {
        return { ...previousState, [val_field]: val };
      });
    } else if (dealDisplayState === "edit_deal") {
      setshowSearchUsersExisting((previousState) => {
        return { ...previousState, [val_field]: true };
      });
      setDealProjectDetailsExisting((previousState) => {
        return { ...previousState, [val_field]: val };
      });
    }
  };

  const handleCheckboxFieldChange = (e) => {
    if (e) {
      setDealProjectDetails((previousState) => {
        return { ...previousState, confedential: "Yes" };
      });
    } else {
      setDealProjectDetails((previousState) => {
        return { ...previousState, confedential: "No" };
      });
    }
  };

  const handleTextFieldChange = (val, val_field) => {
    if (dealDisplayState === "create_deal") {
      setDealProjectDetails((previousState) => {
        return { ...previousState, [val_field]: val };
      });
    } else if (dealDisplayState === "edit_deal") {
      setDealProjectDetailsExisting((previousState) => {
        return { ...previousState, [val_field]: val };
      });
    }
  };

  const searchResultClick = (val, val_field) => {
    if (dealDisplayState === "create_deal") {
      setshowSearchUsers((previousState) => {
        return { ...previousState, [val_field]: false };
      });
      setDealProjectDetails((previousState) => {
        return { ...previousState, [val_field]: val };
      });
    } else if (dealDisplayState === "edit_deal") {
      setshowSearchUsersExisting((previousState) => {
        return { ...previousState, [val_field]: false };
      });
      setDealProjectDetailsExisting((previousState) => {
        return { ...previousState, [val_field]: val };
      });
    }
  };

  const getTeamMembers = () => {
    if (dealDisplayState === "create_deal") {
      var team_members = dealProjectDetails.team_members.map((item) => {
        return { value: item, label: item };
      });
      return team_members;
    } else if (dealDisplayState === "edit_deal") {
      var team_members_existing = dealProjectDetailsExisting.team_members.map(
        (item) => {
          return { value: item, label: item };
        }
      );
      return team_members_existing;
    }
  };

  const getQRP = () => {
    if (dealDisplayState === "create_deal") {
      return dealProjectDetails["qrp_details"];
      // return [
      //   {
      //     value: dealProjectDetails["qrp_details"],
      //     label: dealProjectDetails["qrp_details"],
      //   },
      // ];
    } else if (dealDisplayState === "edit_deal") {
      return dealProjectDetailsExisting["qrp_details"];
      // return {
      //   value: dealProjectDetailsExisting["qrp_details"],
      //   label: dealProjectDetailsExisting["qrp_details"],
      // };
    }
  };

  const changeQRP = (e) => {
    if (dealDisplayState === "create_deal") {
      setDealProjectDetails((previousState) => {
        return { ...previousState, qrp_details: e };
      });
    } else if (dealDisplayState === "edit_deal") {
      setDealProjectDetailsExisting((previousState) => {
        return { ...previousState, qrp_details: e };
      });
    }
  };

  const getEngagementLeader = () => {
    if (dealDisplayState === "create_deal") {
      return dealProjectDetails["engagement_leader"];
      // return {
      //   value: dealProjectDetails["engagement_leader"],
      //   label: dealProjectDetails["engagement_leader"],
      // };
    } else if (dealDisplayState === "edit_deal") {
      return dealProjectDetailsExisting["engagement_leader"];
      // return {
      //   value: dealProjectDetailsExisting["engagement_leader"],
      //   label: dealProjectDetailsExisting["engagement_leader"],
      // };
    }
  };

  const getEngagementManager = () => {
    if (dealDisplayState === "create_deal") {
      return dealProjectDetails["engagement_manager"];
      // return {
      //   value: dealProjectDetails["engagement_manager"],
      //   label: dealProjectDetails["engagement_manager"],
      // };
    } else if (dealDisplayState === "edit_deal") {
      return dealProjectDetailsExisting["engagement_manager"];
      // return {
      //   value: dealProjectDetailsExisting["engagement_manager"],
      //   label: dealProjectDetailsExisting["engagement_manager"],
      // };
    }
  };

  const changeEngagementLeader = (e) => {
    if (dealDisplayState === "create_deal") {
      setDealProjectDetails((previousState) => {
        return { ...previousState, engagement_leader: e };
      });
    } else if (dealDisplayState === "edit_deal") {
      setDealProjectDetailsExisting((previousState) => {
        return { ...previousState, engagement_leader: e };
      });
    }
  };

  const changeEngagementManager = (e) => {
    debugger;
    if (dealDisplayState === "create_deal") {
      setDealProjectDetails((previousState) => {
        return { ...previousState, engagement_manager: e };
      });
    } else if (dealDisplayState === "edit_deal") {
      setDealProjectDetailsExisting((previousState) => {
        return { ...previousState, engagement_manager: e };
      });
    }
  };

  const changeTeamSelect = (e) => {
    var new_team_members = [];
    e.forEach((item) => {
      new_team_members.push(item.value);
    });

    if (dealDisplayState === "create_deal") {
      setDealProjectDetails((previousState) => {
        return { ...previousState, team_members: new_team_members };
      });
    } else if (dealDisplayState === "edit_deal") {
      setDealProjectDetailsExisting((previousState) => {
        return { ...previousState, team_members: new_team_members };
      });
    }
  };

  // States for checklist management
  // Create Static One Time Props for Progress Bar
  var checklist_static_data = {
    steps: [],
    steps_hints: [],
    steps_status: [],
  };

  var checklist_response_obj_static = {};
  for (var i = 0; i < checklistData.length; i++) {
    checklist_static_data.steps.push(String(i + 1));
    checklist_static_data.steps_hints.push(checklistData[i]["question_title"]);
    checklist_static_data.steps_status.push(false);
    checklist_response_obj_static["question_number_" + (i + 1)] = {
      selected_options: [],
      edit_by: "",
      time_stamp: null,
    };
  }
  const [current_question, updateCurrentQuestion] = useState(0);
  const [steps_status_state, updateStepStatus] = useState(
    checklist_static_data.steps_status
  );
  const [checklist_response_obj, updateChecklistResponseObj] = useState(
    checklist_response_obj_static
  );

  // State Management functions for Checklist
  const confirmEditSurvey = (response) => {
    if (response["status"] === 200 && response["errors"] === false) {
      //toastNotify(response["msg"],"success")
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify(response["msg"], "error");
    } else if (response["status"] === 500) {
      toastNotify("Could not create deal - Server Connection Error!", "error");
    }
  };
  const confirmChecklistDownload = (response) => {
    setShowLoader(false);
    if (response["status"] === 200 && response["errors"] === false) {
      // Write code for file download
      const xlname = deal_name.length
        ? deal_name
        : dealProjectDetailsExisting["project_name"];
      saveByteArray(
        xlname + ".xlsx",
        response["output_filestring"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      setDealProjectDetailsExisting({
        project_name: "",
        created_by: "",
        created_on: Date.now(),
        qrp_details: "",
        engagement_leader: "",
        engagement_manager: "",
        team_members: [],
        client_name: "",
        client_description: "",
        target_name: "",
        target_description: "",
        transaction_description: "",
        scope_of_work_desc: "",
        status: 0,
        confedential: "No",
      });
      //after save and checklist
      setDealProjectDetails({
        project_name: "",
        created_by: "",
        created_on: Date.now(),
        qrp_details: "",
        engagement_leader: "",
        engagement_manager: "",
        team_members: [],
        client_name: "",
        client_description: "",
        target_name: "",
        target_description: "",
        transaction_description: "",
        scope_of_work_desc: "",
        status: 0,
        confedential: "No",
      });
      setDealDisplayState("deal_management");
      toastNotify("Checklist generated!", "success");
      // navigate(-1);
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify("Checklist could not be generated!", "error");
    } else if (response["status"] === 500) {
      toastNotify("Could not create deal - Server Connection Error!", "error");
    }
  };
  const saveAndNextChecklist = () => {
    if (current_question < steps_status_state.length - 1) {
      // Check if minimum choices are selected
      var current_checklist_choices =
        checklist_response_obj["question_number_" + (current_question + 1)][
          "selected_options"
        ];
      var current_minimum_choices =
        checklistData[current_question]["minimum_choices"];
      if (
        current_checklist_choices.length == null ||
        current_checklist_choices.length < current_minimum_choices
      ) {
        return toastNotify(
          "Please select minimum " + current_minimum_choices + " choices.",
          "error"
        );
      }

      // Call survey response API to save data
      var paylod = {
        deal_id: deal_id,
        user_id: user.user_id,
        response_obj: JSON.stringify(checklist_response_obj),
        time_stamp: Date.now(),
      };
      var headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer 1234",
      };
      var response = fetchData(
        "editSurvey",
        paylod,
        "POST",
        headers,
        confirmEditSurvey
      );

      // Update checklist choices for current question
      // Move to next question and confirm current questions status
      updateStepStatus((previousState) => {
        let newState = previousState;
        newState[current_question] = true;
        return newState;
      }); // Update current question's status
      updateCurrentQuestion((previousState) => previousState + 1); // Navigate to next question
    } else {
      // Check if all questions have been answered
      var all_questions_answered = true;
      for (var i = 0; i < checklistData.length; i++) {
        var current_checklist_choices =
          checklist_response_obj["question_number_" + (i + 1)][
            "selected_options"
          ];
        if (
          current_checklist_choices.length === 0 ||
          current_checklist_choices.length === null
        ) {
          toastNotify("Question - " + (i + 1) + " is unanswered!", "error");
          all_questions_answered = false;
        }
      }
      if (all_questions_answered) {
        // Call download API
        var paylod = {
          resp_obj: checklist_response_obj,
          deal_id: deal_id,
          user_id: user.user_id,
        };
        var headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer 1234",
        };

        setShowLoader(true);
        //toastNotify("Relax while we deliver tailored work plan for your deal");

        var response_checklist_download = fetchData(
          "generatechecklist",
          paylod,
          "POST",
          headers,
          confirmChecklistDownload
        );
      }
    }
  };
  const gotoQuestionChecklist = (question_number) => {
    updateCurrentQuestion(question_number); // Navigate to next question
  };

  // Callback function to get selected choices for a question
  const getChecklistChoices = (question_number, selected_choices, append) => {
    if (append) {
      // Check for single click

      // Check if already the choices quota is used
      var current_state = false;
      var current_checklist_choices =
        checklist_response_obj["question_number_" + (current_question + 1)][
          "selected_options"
        ];
      var already_selected = current_checklist_choices.length;
      var allowed_choices =
        checklistData[Number(question_number) - 1]["allowed_choices"];
      current_checklist_choices.includes(selected_choices[0])
        ? (current_state = true)
        : (current_state = false);

      if (already_selected < allowed_choices) {
        updateChecklistResponseObj((previousState) => {
          var newState = { ...previousState };
          var current_choices =
            newState["question_number_" + question_number][
              "selected_options"
            ].slice();
          var new_choices = [];
          if (current_state) {
            // Pop the value - turn off button
            new_choices = current_choices.filter(
              (item) => item !== selected_choices[0]
            );
          } else {
            // Add the value to array turn on button
            current_choices.push(selected_choices[0]);
            new_choices = current_choices;
          }
          newState["question_number_" + question_number]["selected_options"] =
            new_choices;
          newState["question_number_" + question_number]["edit_by"] =
            user.user_id;
          newState["question_number_" + question_number]["time_stamp"] =
            Date.now();
          return newState;
        });
      } else if (
        already_selected === allowed_choices &&
        current_state === true
      ) {
        updateChecklistResponseObj((previousState) => {
          var newState = { ...previousState };
          var current_choices =
            newState["question_number_" + question_number][
              "selected_options"
            ].slice();
          var new_choices = [];
          // Pop the value - turn off button
          new_choices = current_choices.filter(
            (item) => item !== selected_choices[0]
          );
          newState["question_number_" + question_number]["selected_options"] =
            new_choices;
          newState["question_number_" + question_number]["edit_by"] =
            user.user_id;
          newState["question_number_" + question_number]["time_stamp"] =
            Date.now();
          return newState;
        });
      } else {
        toastNotify(
          "Maximum " + allowed_choices + " choices allowed.",
          "error"
        );
      }
    } else {
      // Check for toggle all state - set full state
      updateChecklistResponseObj((previousState) => {
        var newState = { ...previousState };
        newState["question_number_" + question_number]["selected_options"] =
          selected_choices;
        newState["question_number_" + question_number]["edit_by"] =
          user.user_id;
        newState["question_number_" + question_number]["time_stamp"] =
          Date.now();
        return newState;
      });
    }
  };

  // Function for retrieving existing checklist
  const confirmEditDeal = (response) => {
    if (response["status"] === 200 && response["errors"] === false) {
      //toastNotify(response["msg"],"success")
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify(response["msg"], "error");
    } else if (response["status"] === 500) {
      toastNotify("Could not update deal - Server Connection Error!", "error");
    }

    // Fetch latest survey response
    var paylod = {
      deal_id: deal_id,
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var response_survey = fetchData(
      "viewSurvey",
      paylod,
      "POST",
      headers,
      confirmSurveyResponseObj
    );
  };
  const saveAndNextExistingDeal = (e) => {
    e.preventDefault();
    // Call the update deal data API
    var paylod = {
      deal_id: deal_id,
      ...dealProjectDetailsExisting,
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var response = fetchData(
      "edit_deal",
      paylod,
      "POST",
      headers,
      confirmEditDeal
    );
  };

  // Fetch details from backend - set initial state values from there
  /*
  const confirmGetDeal = (response) =>{
    if (response["status"] === 200 && response["errors"]===false){
      toastNotify(response["msg"],"success")
      setDealProjectDetailsExisting(response["data"])
      setDealDisplayState("edit_deal");
    }else if(response["status"] === 200 && response["errors"]===true){
      toastNotify(response["msg"],"error")
    }else if(response["status"] === 500){
      toastNotify("Could not update deal - Server Connection Error!","error")          
    }
  }

  const getDealDetails = (deal_id) =>{
    // Call the get deal data API
    var paylod = {
      "deal_id":deal_id,
      "user_id":user.user_id,
    }
    var headers = {
      'Content-Type':"application/json",
      'Authorization':'Bearer 1234'
    }
    var response = fetchData("get_deal",paylod,"POST",headers,confirmGetDeal); 
  }

  */

  const getDealDetails = (deal_id) => {
    var current_deal_data = {};
    for (i = 0; i < deals_data.length; i++) {
      if (deals_data[i]["deal_id"] == deal_id) {
        current_deal_data = deals_data[i];
        break;
      }
    }
    var current_team_members = current_deal_data["team_members"].split("||");
    setDealId(deal_id);
    setDealProjectDetailsExisting({
      ...current_deal_data,
      team_members: current_team_members,
    });
    setDealDisplayState("edit_deal");
  };
  // API Callback for confirming survey obj
  const confirmSurveyResponseObj = (response) => {
    if (response["status"] === 200 && response["errors"] === false) {
      //toastNotify(response["msg"],"success")
      updateChecklistResponseObj(JSON.parse(response["survey"]));
      var survey = JSON.parse(response["survey"]);
      var step_status = [];
      Object.keys(survey).forEach((key) => {
        survey[key]["selected_options"].length > 0
          ? step_status.push(true)
          : step_status.push(false);
      });
      updateStepStatus(step_status);
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify(response["msg"], "error");
    } else if (response["status"] === 500) {
      toastNotify(
        "Could not create survey - Server Connection Error!",
        "error"
      );
    }
    //  Move to checklist
    setDealDisplayState("edit_survey");
  };

  // API Callback for Adding Deals
  const confirmDealAdded = (response) => {
    setShowLoader(false);
    if (response["status"] === 200 && response["errors"] === false) {
      toastNotify(response["msg"], "success");
      var deal_info = response["deal_info"];
      setDealId(deal_info["deal_id"]);
      setDealName(deal_info["project_name"]);
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify(response["msg"], "error");
    } else if (response["status"] === 500) {
      toastNotify("Could not create deal - Server Connection Error!", "error");
    }

    // Convert current data to existing deal data with updated status = 1
    setDealProjectDetailsExisting({ ...dealProjectDetails, status: 1 });
    setIsSaveBtnDisabled(false);
    // Re-initialize new deal state
    setDealProjectDetails({
      project_name: "",
      created_by: user.user_id,
      created_on: Date.now(),
      qrp_details: "",
      engagement_leader: "",
      engagement_manager: "",
      team_members: [],
      client_name: "",
      client_description: "",
      target_name: "",
      target_description: "",
      transaction_description: "",
      scope_of_work_description: "",
      status: 0,
      confedential: "No",
    });

    // Update survey responses
    var survey_responses = {};
    for (var i = 0; i < checklistData.length; i++) {
      var current_question_obj = {
        selected_options: [],
        edit_by: user.user_id,
        timestamp: Date.now(),
      };
      survey_responses["question_number_" + (i + 1)] = current_question_obj;
    }
    var paylod = {
      deal_id: deal_info["deal_id"],
      user_id: user.user_id,
      response_obj: JSON.stringify(survey_responses),
      time_stamp: Date.now(),
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var response_survey = fetchData(
      "editSurvey",
      paylod,
      "POST",
      headers,
      confirmSurveyResponseObj
    );
  };

  const goBack = (e) => {
    // setFilteredDealData(deals_data);
    // setDealDisplayState("deal_management")

    setDealProjectDetails({
      project_name: "",
      created_by: "",
      created_on: Date.now(),
      qrp_details: "",
      engagement_leader: "",
      engagement_manager: "",
      team_members: [],
      client_name: "",
      client_description: "",
      target_name: "",
      target_description: "",
      transaction_description: "",
      scope_of_work_desc: "",
      status: 0,
      confedential: "No",
    });
    // navigate(-1);
  };
  const confirmCreateDeal = async (e) => {
    setShowLoader(true);
    e.preventDefault();
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var paylod = { ...dealProjectDetails, status: 1 };
    var response = await fetchData(
      "add_deal",
      paylod,
      "POST",
      headers,
      confirmDealAdded
    );
  };

  // State for deleting deals
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const cancelDeleteDeal = () => {
    setShowDeleteModal(false);
  };
  const confirmDealDeleted = (response) => {
    setShowLoader(false);
    if (response["status"] === 200 && response["errors"] === false) {
      toastNotify(response["msg"], "success");
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify(response["msg"], "error");
    } else if (response["status"] === 500) {
      toastNotify("Server Connection Error!", "error");
    }
    setDealDisplayState("deal_management");
  };
  const confirmDeleteDeal = () => {
    // Call the delete user API by passing variable - userEmailValueExisting
    setShowDeleteModal(true);
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var paylod = {
      deal_id_to_delete: deal_id,
      user_id: user.user_id,
    };
    setShowLoader(true);
    var response = fetchData(
      "delete_deal",
      paylod,
      "POST",
      headers,
      confirmDealDeleted
    );
  };
  const delete_modal_action_buttons = [
    {
      text: "No, Cancel",
      style: {
        cursor: "pointer",
        width: "150px",
        height: "40px",
        backgroundColor: "#ffffff",
        border: "2px solid #DE3163",
        color: "#DE3163",
        padding: "5px",
        margin: "10px",
        fontSize: "16px",
        fontWeight: "bold",
        outline: "none",
      },
      callback: cancelDeleteDeal,
      type: "secondary",
    },
    {
      text: "Yes, Delete",
      style: {
        cursor: "pointer",
        width: "150px",
        height: "40px",
        backgroundColor: "#DE3163",
        border: "2px solid #DE3163",
        color: "#ffffff",
        padding: "5px",
        margin: "10px",
        fontSize: "16px",
        fontWeight: "bold",
        outline: "none",
      },
      callback: confirmDeleteDeal,
      type: "primary",
    },
  ];

  // State for creating users through project form
  const [userInfoCreateUserAdmin, setUserInfoCreateUserAdmin] = useState({
    user_name: "",
    user_id: "",
    mobile_no: "",
    user_type: "",
    designation: "",
  });
  const [showAdminCreateUserModal, setAdminShowCreateUserModal] =
    useState(false);
  const showCreateUserModalAdmin = () => {
    setAdminShowCreateUserModal(true);
  };
  const cancelAdminCreateUser = () => {
    setUserInfoCreateUserAdmin({
      user_name: "",
      user_id: "",
      mobile_no: "",
      user_type: "",
      designation: "",
    });
    setAdminShowCreateUserModal(false);
  };
  const setAddUserInfoAdmin = (val, val_field) => {
    setUserInfoCreateUserAdmin((previousState) => {
      return { ...previousState, [val_field]: val };
    });
  };

  // Admin user addition callback
  const confirmAdminUserAdded = (response) => {
    setShowLoader(false);
    if (response["status"] === 200 && response["errors"] === false) {
      setAdminShowCreateUserModal(false);
      toastNotify(response["msg"], "success");
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify(response["msg"], "error");
    } else if (response["status"] === 500) {
      toastNotify("Server Connection Error!", "error");
    }
    setUserInfoCreateUserAdmin({
      user_name: "",
      user_id: "",
      mobile_no: "",
      user_type: "",
      designation: "",
    });
  };
  const confirmAdminCreateUser = (e) => {
    // Call the delete user API by passing variable - userEmailValueExisting
    setShowLoader(true);
    e.preventDefault();
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var paylod = {
      user_id: userInfoCreateUserAdmin["user_id"],
      user_name: userInfoCreateUserAdmin["user_name"],
      user_type: userInfoCreateUserAdmin["user_type"],
      mobile_no: userInfoCreateUserAdmin["mobile_no"],
      designation: userInfoCreateUserAdmin["designation"],
      modified_by: user.user_id,
      modified_on: Date.now(),
    };

    var response = fetchData(
      "add_user",
      paylod,
      "POST",
      headers,
      confirmAdminUserAdded
    );
  };

  // Add Event listener for outside click events
  const search_fields = [
    "qrp_details",
    "engagement_leader",
    "engagement_manager",
    "client_name",
    "target_name",
  ];
  const handleClickOutside = (e) => {
    var element_name = e.target.name;
    if (search_fields.includes(element_name) === false) {
      if (dealDisplayState === "create_deal") {
        setshowSearchUsers({
          project_name: false,
          created_by: false,
          qrp_details: false,
          engagement_leader: false,
          engagement_manager: false,
          client_name: false,
          target_name: false,
        });
      } else if (dealDisplayState === "edit_deal") {
        setshowSearchUsersExisting({
          project_name: false,
          created_by: false,
          qrp_details: false,
          engagement_leader: false,
          engagement_manager: false,
          client_name: false,
          target_name: false,
        });
      }
    }
  };
  useEffect(() => {
    // Remove existing event listener
    try {
      document.removeEventListener("click", handleClickOutside);
    } catch (err) {}
    // Add new event listener
    document.addEventListener("click", handleClickOutside);
  }, [dealDisplayState]);

  if (dealDisplayState === "deal_management") {
    return dealsFetched ? (
      <div className="page_content">
        <div className="menubar_simple">
          <div className="menubar_title">Deals Management</div>
          <div className="menubar_actions">
            <ExpandSearch
              filtering_function={filterDealData}
              search_placeholder="Search project"
              direction="left"
            ></ExpandSearch>
            <Button
              kind="primary"
              compact
              icon="icon-circle-plus-outline"
              onClick={() => {
                setDealDisplayState("create_deal");
              }}
            >
              Create Deal
            </Button>
          </div>
        </div>
        <DataTbl
          highlightOnHover={true}
          classname="user_table"
          columns={columns}
          data={filteredDealData}
          customStyles={customStyles}
          pagination_per_page={15}
          pagination_options={[10, 15, 20, 25, 50, 100]}
        />
        {showLoader && (
          <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        )}
        <ToastContainer autoClose={2000} />
      </div>
    ) : (
      <div className="loader-dial">
        <div className="stop-overlay"></div>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    );
  } else if (dealDisplayState === "create_deal") {
    return (
      <div className="page_content">
        <div className="menubar_simple">
          <div
            className="menubar_action_left"
            onClick={() => {
              setFilteredDealData(deals_data);
              setDealDisplayState("deal_management");
              setDealProjectDetails({
                project_name: "",
                created_by: "",
                created_on: Date.now(),
                qrp_details: "",
                engagement_leader: "",
                engagement_manager: "",
                team_members: [],
                client_name: "",
                client_description: "",
                target_name: "",
                target_description: "",
                transaction_description: "",
                scope_of_work_desc: "",
                status: 0,
                confedential: "No",
              });
            }}
          >
            <img
              src={iconsIndex["arrow_back_icon"]}
              alt=""
              width="25px"
              height="25px"
            ></img>
          </div>
          <div className="menubar_title title_right">
            {dealProjectDetails["project_name"]}
          </div>
          <MenuBar status={dealProjectDetails["status"]}></MenuBar>
          <div className="menubar_actions">
            <Button
              kind="secondary"
              compact
              onClick={(e) => {
                goBack(e);
              }}
              style={{ margin: "10px" }}
            >
              Clear
            </Button>
            <Button
              kind="primary"
              compact
              onClick={(e) => {
                confirmCreateDeal(e);
              }}
              style={{ margin: "10px" }}
            >
              Save and Next
            </Button>
          </div>
        </div>
        <ProjectInfo
          dealProjectDetails={dealProjectDetails}
          showCreateUserModal={showCreateUserModalAdmin}
          handleInputFieldChange={handleInputFieldChange}
          handleTextFieldChange={handleTextFieldChange}
          searchResultClick={searchResultClick}
          user_search_data={user_search_data}
          user_select_data={user_select_data}
          engagement_leader_search_data={engagement_leader_search_data}
          engagement_manager_search_data={engagement_manager_search_data}
          showSearchUsers={showSearchUsers}
          getTeamMembers={getTeamMembers}
          getQRP={getQRP}
          getEngagementLeader={getEngagementLeader}
          getEngagementManager={getEngagementManager}
          changeQRP={changeQRP}
          changeEngagementLeader={changeEngagementLeader}
          changeEngagementManager={changeEngagementManager}
          changeTeamSelect={changeTeamSelect}
          user_type={user.user_type}
          handleCheckboxFieldChange={handleCheckboxFieldChange}
        ></ProjectInfo>
        {showAdminCreateUserModal && (
          <CreateUserModal
            heading="Create new user"
            user_info={userInfoCreateUserAdmin}
            setUserInfo={setAddUserInfoAdmin}
            cancel={cancelAdminCreateUser}
            submit={confirmAdminCreateUser}
          ></CreateUserModal>
        )}
        {showLoader && (
          <div className="loader-dial">
            <div className="stop-overlay"></div>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
        <ToastContainer autoClose={20} />
      </div>
    );
  } else if (dealDisplayState === "edit_deal") {
    return (
      <div className="page_content">
        <div className="menubar_simple">
          <div
            className="menubar_action_left"
            onClick={() => {
              setFilteredDealData(deals_data);
              setDealDisplayState("deal_management");
              setDealProjectDetailsExisting({
                project_name: "",
                created_by: "",
                created_on: Date.now(),
                qrp_details: "",
                engagement_leader: "",
                engagement_manager: "",
                team_members: [],
                client_name: "",
                client_description: "",
                target_name: "",
                target_description: "",
                transaction_description: "",
                scope_of_work_desc: "",
                status: 0,
                confedential: "No",
              });
            }}
          >
            <img
              src={iconsIndex["arrow_back_icon"]}
              alt=""
              width="25px"
              height="25px"
            ></img>
          </div>
          <div className="menubar_title title_right">
            {dealProjectDetailsExisting["project_name"]}
          </div>
          <MenuBar status={dealProjectDetailsExisting["status"]}></MenuBar>
          <div className="menubar_actions">
            <Button
              kind="secondary"
              compact
              onClick={() => {
                setShowDeleteModal(true);
              }}
              style={{ margin: "10px" }}
            >
              Delete
            </Button>
            <Button
              kind="primary"
              disabled={isSaveBtnDisabled}
              compact
              onClick={(e) => {
                saveAndNextExistingDeal(e);
              }}
              style={{ margin: "10px" }}
            >
              Save and Next
            </Button>
          </div>
        </div>
        <ProjectInfo
          dealProjectDetails={dealProjectDetailsExisting}
          showCreateUserModal={showCreateUserModalAdmin}
          handleInputFieldChange={handleInputFieldChange}
          handleTextFieldChange={handleTextFieldChange}
          searchResultClick={searchResultClick}
          user_search_data={user_search_data}
          user_select_data={user_select_data}
          engagement_leader_search_data={engagement_leader_search_data}
          engagement_manager_search_data={engagement_manager_search_data}
          showSearchUsers={showSearchUsersExisting}
          getTeamMembers={getTeamMembers}
          getQRP={getQRP}
          getEngagementLeader={getEngagementLeader}
          getEngagementManager={getEngagementManager}
          changeQRP={changeQRP}
          changeEngagementLeader={changeEngagementLeader}
          changeEngagementManager={changeEngagementManager}
          changeTeamSelect={changeTeamSelect}
          user_type={user.user_type}
          handleCheckboxFieldChange={handleCheckboxFieldChange}
        ></ProjectInfo>
        {showAdminCreateUserModal && (
          <CreateUserModal
            heading="Create new user"
            user_info={userInfoCreateUserAdmin}
            setUserInfo={setAddUserInfoAdmin}
            cancel={cancelAdminCreateUser}
            submit={confirmAdminCreateUser}
          ></CreateUserModal>
        )}
        {showDeleteModal && (
          <ActionModal
            actions_obj={delete_modal_action_buttons}
            heading="Delete Project?"
            msg="All data related to this project will be lost permanently.&#10;Are you sure you want to delete this project?"
          ></ActionModal>
        )}
        {showLoader && (
          <div className="loader-dial">
            <div className="stop-overlay"></div>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
        <ToastContainer autoClose={20} />
      </div>
    );
  } else if (dealDisplayState === "edit_survey") {
    return (
      <div className="page_content">
        <div className="menubar_simple">
          <div
            className="menubar_action_left"
            onClick={() => {
              setFilteredDealData(deals_data);
              setDealDisplayState("deal_management");
            }}
          >
            <img
              src={iconsIndex["arrow_back_icon"]}
              alt=""
              width="25px"
              height="25px"
            ></img>
          </div>
          {/* <div className='menubar_title title_right'>{deal_name}</div> */}
          <div className="menubar_title title_right">
            {dealProjectDetailsExisting["project_name"]}
          </div>
          <MenuBar status={dealProjectDetailsExisting["status"]}></MenuBar>
          <div className="menubar_actions"></div>
        </div>
        <Survey
          status={dealProjectDetailsExisting["status"]}
          current_question={current_question}
          updateCurrentQuestion={updateCurrentQuestion}
          steps_status_state={steps_status_state}
          updateStepStatus={updateStepStatus}
          checklist_static_data={checklist_static_data}
          checklist_response_obj={checklist_response_obj}
          updateChecklistChoices={updateChecklistResponseObj}
          saveAndNext={saveAndNextChecklist}
          gotoQuestion={gotoQuestionChecklist}
          getChecklistChoices={getChecklistChoices}
        ></Survey>

        {showLoader && (
          <div className="loader-dial">
            <div className="stop-overlay"></div>

            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
            <br></br>
            <p>Relax while we generate a tailored workplan</p>
          </div>
        )}
        <ToastContainer autoClose={20} />
      </div>
    );
  }
}
