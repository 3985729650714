import React, { useEffect } from 'react'
import { iconsIndex } from '../icons/icons_index';
import '../GlobalVariables'
import { useSearchParams, Outlet, useLocation, Navigate } from 'react-router-dom';
import { useUserAuth } from '../hooks/useUserAuth'



export default function Logout() {
    const { user, setUser } = useUserAuth();

    useEffect(() => {
        // setUser({user_verified:false,user_role:null})
        setUser((previousState) => {
            return { ...previousState, user_verified: false,user_role:null, user_type: null, user_id: null, username: null, user_access_token: null } // access token to be added here.
        });
    }, [])

}