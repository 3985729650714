import { createContext, useState } from "react";

const userAuthContext = createContext();

export const UserAuthContext = ({ children }) => {
  const [user, setUser] = useState({user_verified:false,user_role:null});

  return (
    <userAuthContext.Provider value={{user,setUser}}>
        {children}
    </userAuthContext.Provider>
  );
}

export default userAuthContext;

