import React from "react";
import "./ProjectInfo.css";
// import Select from "react-select";
import TextField from "../components/TextField";
import InputField from "../components/InputField";
import CreateCircleIcon from "../icons/CreateCircleIcon";
import {
  Combobox,
  Input,
  Select,
  TextArea,
  CheckboxGroup,
  Checkbox,
} from "@appkit4/react-components";

export default function ProjectInfo(props) {
  // Define required states
  const dealProjectDetails = props.dealProjectDetails;
  const showCreateUserModal = props.showCreateUserModal;
  const handleInputFieldChange = props.handleInputFieldChange;
  const handleCheckboxFieldChange = props.handleCheckboxFieldChange;
  const handleTextFieldChange = props.handleTextFieldChange;
  const searchResultClick = props.searchResultClick;
  const user_search_data = props.user_search_data;
  const engagement_leader_search_data = props.engagement_leader_search_data;
  const engagement_manager_search_data = props.engagement_manager_search_data;

  const showSearchUsers = props.showSearchUsers;
  const getQRP = props.getQRP;
  const getEngagementLeader = props.getEngagementLeader;
  const getEngagementManager = props.getEngagementManager;
  const getTeamMembers = props.getTeamMembers;
  const changeQRP = props.changeQRP;
  const changeEngagementLeader = props.changeEngagementLeader;
  const changeEngagementManager = props.changeEngagementManager;
  const changeTeamSelect = props.changeTeamSelect;
  const user_select_data = props.user_select_data;
  const user_type = props.user_type;
  // Define other elements
  const selectCSSStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#ffffff",
      fontSize: "14px",
      width: "305px",
      height: "100px",
      outline: "none",
      border: "none",
      boxShadow: "none",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f0f0f0" : "#ffffff",
        color: "#000000",
        width: "305px",
      };
    },
    menuList: (base) => {
      return {
        ...base,
        "::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
        },
        "::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 2px rgba(0,0,0,0.3)",
          borderRadius: "10px",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          "-webkit-box-shadow": "inset 0 0 2px rgba(0,0,0,0.5)",
          backgroundColor: "#909090",
        },
      };
    },
  };

  const selectCSSStyless = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#ffffff",
      fontSize: "14px",
      width: "305px",
      height: "8px",
      outline: "none",
      border: "none",
      boxShadow: "none",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f0f0f0" : "#ffffff",
        color: "#000000",
        width: "305px",
      };
    },
    menuList: (base) => {
      return {
        ...base,
        "::-webkit-scrollbar": {
          width: "5px",
          height: "5px",
        },
        "::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 2px rgba(0,0,0,0.3)",
          borderRadius: "10px",
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: "10px",
          "-webkit-box-shadow": "inset 0 0 2px rgba(0,0,0,0.5)",
          backgroundColor: "#909090",
        },
      };
    },
  };

  return (
    <div className="deal_actions_outer">
      <div className="deal_actions_inner">
        <div className="deal_detail_container">
          <div className="container-small">
            <div className="deal_detail_container_design">
              <div className="card-header">Project Details</div>
              <div className="label-name">Project Name</div>
              <Input
                type={"text"}
                placeholder={"Type here"}
                value={dealProjectDetails["project_name"]}
                onChange={(e) => handleInputFieldChange(e, "project_name")}
                hideTitleOnInput
                fontSize
              />
              {/* <InputField
                type="text"
                value={dealProjectDetails["project_name"]}
                placeholder="Type here"
                label="Project Name"
                name="project_name"
                onchange={handleInputFieldChange}
                actions={[]}
                search={{
                  apply: false,
                  search_callback: null,
                  search_list: null,
                }}
                show_search_div={false}
              /> */}
            </div>
          </div>
          <div className="container-mid">
            <div className="deal_detail_container_design">
              <div className="card-header">Team Details</div>

              {/* <InputField
                        type="text"
                        value={dealProjectDetails["qrp_details"]}
                        placeholder="Type here"
                        label="QRP (Optional)"
                        name="qrp_details"
                        onchange={handleInputFieldChange}
                        actions = {user_type==="admin"?[{action_icon:<CreateCircleIcon fill = "#289D8E"></CreateCircleIcon>,action_callback:showCreateUserModal,action_text:"Create new user"}]:[]}
                                search={{ apply: true, search_callback: searchResultClick, search_list: engagement_leader_search_data }}
                        show_search_div = {showSearchUsers["qrp_details"]}/>

                        <InputField
                        type="text"
                        value={dealProjectDetails["engagement_leader"]}
                        placeholder="Type here"
                        label="Engagement Leader"
                        name="engagement_leader"
                        onchange={handleInputFieldChange}
                        actions = {user_type==="admin"?[{action_icon:<CreateCircleIcon fill = "#289D8E"></CreateCircleIcon>,action_callback:showCreateUserModal,action_text:"Create new user"}]:[]}
                                search={{ apply: true, search_callback: searchResultClick, search_list: engagement_leader_search_data }}
                        show_search_div = {showSearchUsers["engagement_leader"]}/>   */}

              <div className="label-name">QRP (Optional)</div>
              {/* <Select
                isSearchable={false}
                name="Qrp"
                options={engagement_leader_search_data}
                onChange={changeQRP}
                value={getQRP()}
                styles={selectCSSStyless}
                menuPlacement="auto"  
                menuPosition="fixed"
              /> */}
              <Select
                data={engagement_leader_search_data}
                placeholder="Select"
                onSelect={(e) => changeQRP(e)}
                value={getQRP()}
                hideTitleOnInput
              />

              <div className="label-name">Engagement Leader</div>
              {/* <Select
                isSearchable={false}
                name="Engagement leader"
                options={engagement_leader_search_data}
                onChange={changeEngagementLeader}
                value={getEngagementLeader()}
                styles={selectCSSStyless}
                menuPlacement="auto"
                menuPosition="fixed"
              /> */}
              <Select
                data={engagement_leader_search_data}
                placeholder="Select"
                onSelect={(e) => changeEngagementLeader(e)}
                value={getEngagementLeader()}
                hideTitleOnInput
              />

              {/* <InputField
                        type="text"
                        value={dealProjectDetails["engagement_manager"]}
                        placeholder="Type here"
                        label="Engagement Manager"
                        name="engagement_manager"
                        onchange={handleInputFieldChange}
                        actions = {user_type==="admin"?[{action_icon:<CreateCircleIcon fill = "#289D8E"></CreateCircleIcon>,action_callback:showCreateUserModal,action_text:"Create new user"}]:[]}
                                search={{ apply: true, search_callback: searchResultClick, search_list: engagement_manager_search_data }}
                        show_search_div = {showSearchUsers["engagement_manager"]}/>       */}

              <div className="label-name">Engagement Manager</div>
              <Select
                data={engagement_manager_search_data}
                placeholder="Select"
                onSelect={(e) => changeEngagementManager(e)}
                value={getEngagementManager()}
                hideTitleOnInput
              />
              <div className="label-name">Team Member</div>
              <Combobox
                data={user_select_data}
                showSelectAll={false}
                required={false}
                placeholder={"Select"}
                multiple
                valueKey={"value"}
                labelKey={"label"}
                showMultipleTags
              ></Combobox>
              {/* <Select
                isMulti
                name="team_members"
                options={user_select_data}
                onChange={changeTeamSelect}
                value={getTeamMembers()}
                styles={selectCSSStyles}
                menuPlacement="auto"
                menuPosition="fixed"
              /> */}
            </div>
          </div>
        </div>
        <div className="deal_detail_container">
          <div className="deal_detail_container_pattern container-large">
            <div className="deal_detail_container_design">
              <div className="card-header">Deal Specification</div>
              <div className="confidential-container">
                <Checkbox
                  onChange={handleCheckboxFieldChange}
                  value="is-"
                  defaultChecked={false}
                >
                  Confidential
                </Checkbox>
                {/* <span>Confidential</span>
                <input
                  style={{ height: "20px", width: "20px" }}
                  className="is-confindential"
                  value="is-"
                  type="checkbox"
                  onChange={handleCheckboxFieldChange}
                /> */}
              </div>
              <div className="label-name">Client Name</div>
              <Input
                type={"text"}
                placeholder={"Type here"}
                value={dealProjectDetails["client_name"]}
                onChange={(e) => handleInputFieldChange(e, "client_name")}
                hideTitleOnInput
              />
              {/* <InputField
                type="text"
                value={dealProjectDetails["client_name"]}
                placeholder="Type here"
                label="Client Name"
                name="client_name"
                onchange={handleInputFieldChange}
                actions={[]}
                search={{
                  apply: false,
                  search_callback: null,
                  search_list: null,
                }}
                show_search_div={false}
              /> */}
              <div className="label-name">
                Brief description of client business
              </div>
              <TextArea
                title={"Type here (Max 200 char)"}
                maxLength={200}
                value={dealProjectDetails["client_description"]}
                onChange={(e) =>
                  handleInputFieldChange(e, "client_description")
                }
                hideTitleOnInput
              />
              {/* <TextField
                type="text"
                value={dealProjectDetails["client_description"]}
                placeholder="Type here (Max 200 char)"
                label="Brief description of client business"
                name="client_description"
                onchange={handleTextFieldChange}
              /> */}
              <div className="label-name">Target Name</div>
              <Input
                type={"text"}
                placeholder={"Type here"}
                value={dealProjectDetails["target_name"]}
                onChange={(e) => handleInputFieldChange(e, "target_name")}
                hideTitleOnInput
              />
              {/* <InputField
                type="text"
                value={dealProjectDetails["target_name"]}
                placeholder="Type here"
                label="Target Name"
                name="target_name"
                onchange={handleInputFieldChange}
                actions={[]}
                search={{
                  apply: false,
                  search_callback: null,
                  search_list: null,
                }}
                show_search_div={false}
              /> */}
              <div className="label-name">
                Brief description of target business
              </div>
              <TextArea
                title={"Type here (Max 200 char)"}
                maxLength={200}
                value={dealProjectDetails["target_description"]}
                onChange={(e) =>
                  handleInputFieldChange(e, "target_description")
                }
                hideTitleOnInput
              />
              {/* <TextField
                type="text"
                value={dealProjectDetails["target_description"]}
                placeholder="Type here (Max 200 char)"
                label="Brief description of target business"
                name="target_description"
                onchange={handleTextFieldChange}
              /> */}
            </div>
          </div>
        </div>
        <div className="deal_detail_container">
          <div className="deal_detail_container_pattern container-large">
            <div className="deal_detail_container_design">
              <div className="card-header">Other Details</div>
              <div className="label-name">Transaction Description</div>
              <TextArea
                title={"Type here (Max 200 char)"}
                maxLength={200}
                value={dealProjectDetails["transaction_description"]}
                onChange={(e) =>
                  handleInputFieldChange(e, "transaction_description")
                }
                hideTitleOnInput
              />
              {/* <TextField
                type="text"
                value={dealProjectDetails["transaction_description"]}
                placeholder="Type here (Max 200 char)"
                label="Transaction Description"
                name="transaction_description"
                onchange={handleTextFieldChange}
              /> */}
              <div className="label-name">Scope of Work Description</div>
              <TextArea
                title={"Type here (Max 200 char)"}
                maxLength={200}
                value={dealProjectDetails["scope_of_work_desc"]}
                onChange={(e) =>
                  handleInputFieldChange(e, "scope_of_work_desc")
                }
                hideTitleOnInput
              />
              {/* <TextField
                type="text"
                value={dealProjectDetails["scope_of_work_desc"]}
                placeholder="Type here (Max 200 char)"
                label="Scope of Work Description"
                name="scope_of_work_desc"
                onchange={handleTextFieldChange}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
