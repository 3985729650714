// Function to send data to fetch API
const API_ROOT_URL = "https://ipzbpurrapwa001.azurewebsites.net";
// const API_ROOT_URL = "http://localhost:5000"

// Function to fetch data
export const fetchData = async (
  url_extension,
  data_to_send,
  call_method,
  headers,
  promiseCallback
) => {
  let call_URL = `${API_ROOT_URL}/${url_extension}`;
  return await fetch(call_URL, {
    method: call_method,
    headers: headers,
    body: JSON.stringify(data_to_send),
  })
    .then((response) => response.json())
    .then((response_json) => {
      if (response_json["status"] === 200) {
        promiseCallback(response_json);
        return response_json;
      }
    })
    .catch((error) => {
      promiseCallback({
        status: 500,
        error: error,
      });
    });
};

// Convert Hex to RGBA
export const convertHexToRGBA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    opacity = opacity / 100;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function saveByteArray(reportName, byte, mimeType) {
  var processed_filearray = base64ToArrayBuffer(byte);
  var blob = new Blob([processed_filearray], { type: mimeType });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
  link.remove();
}

export function downloadObjectAsJSON(exportObj, exportName) {
  var dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportObj));
  var downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".txt");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}
