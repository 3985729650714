import React from 'react'
import {useState} from 'react'
import BoxSelect from './BoxSelect.js';
import {iconsIndex} from '../icons/icons_index.js';
import './MultiBoxSelect.css';
import toastNotify from './Toast.js';
import { ToastContainer, toast } from 'react-toastify';

function MultiBoxSelect(props) {

  const current_data = props.current_data;
  const allowed_choices = current_data.allowed_choices;
  const question_number = current_data.question_number;
  const options = current_data.question_options;
  const checklist_response_obj = props.checklist_response_obj;
  const checklist_choices = checklist_response_obj["question_number_" + question_number]["selected_options"]; // Array of choice indexes
  const getChecklistChoices = props.getChecklistChoices; // To set global choices for current question (question number,[array of indexes],append or set equal -> true / false)

  var choice_boxes = [];
  options.forEach((item,index)=>{
    var current_id = "question_" + question_number + "_boxoption_" + index;
    choice_boxes.push({"id":current_id,"selected":false,"icon":item.option_icon,"text":item.option_text})
  })
  
  return (
    <div className='multi_select'>
        <div className='multi_select_options'>
            <button className = "multi_select_options_button" onClick={()=>{allowed_choices<options.length?toastNotify("Maximum " + allowed_choices + " choices allowed.","error"):getChecklistChoices(question_number,[...Array(options.length).keys()],false)}}>Select All</button>
            <button className = "multi_select_options_button" onClick={()=>{getChecklistChoices(question_number,[],false)}}>Unselect All</button>
        </div>
    
        <div className='multi_select_layout'>
            {options.map((item,index)=>{
                return <BoxSelect key = {"boxoption_" + index} id = {"boxoption_" + index} index = {index} question_number = {question_number} backgroundColor = "#289D8E" color = "#279B8C" border = "2px solid #279B8C" icon = {iconsIndex[item["option_icon"]]} text = {item["option_text"]} checked = {checklist_choices.includes(index)?true:false} changeState = {getChecklistChoices}></BoxSelect>
            })}
        </div>
        <ToastContainer autoClose={2000} />
    </div>
  )
}

export default MultiBoxSelect;

/*

  const [total_selected,updateSelectedCount] = useState(choice_boxes);  

  // Toggle Box function
  const toggleBox = (id) =>{

    // Check if already the choices quota is used
    var already_selected = checklist_choices.length;
    var current_state = false;
    total_selected.forEach((item) =>{
        if(item.id===id){
            current_state = item.selected;
        }
    })

    if (already_selected<allowed_choices){
        updateSelectedCount(previousState => {
            return previousState.map((item)=>{
                return item.id == id?{...item,selected:!item.selected}:item;
            })
        })
    }
    else if(already_selected===allowed_choices && current_state === true) {
        updateSelectedCount(previousState => {
            return previousState.map((item)=>{
                return item.id == id?{...item,selected:!item.selected}:item;
            })
        })
    }
    else{
        toastNotify("Maximum " + allowed_choices + " choices allowed.","error")
    }
  }

  const toggleAll = (state) =>{
    updateSelectedCount(previousState => {
        return previousState.map((item)=>{
            return {...item,selected:state};
        })
    })
  }

  */