import React from "react";
import "./ExpandSearch.css";
import { useState } from "react";
import { iconsIndex } from "../icons/icons_index";

export default function ExpandSearch(props) {
  const filteringFunction = props.filtering_function;
  const [searchValue, setSearchValue] = useState("");
  const [expandState, setExpandState] = useState(false);

  return (
    <div className="search-container">
      <input
        className={
          "searchbar barpos-" + props.direction + (expandState ? " expand" : "")
        }
        type="search"
        placeholder={props.search_placeholder}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          filteringFunction(e.target.value);
        }}
      />
      <img
        onClick={() => {
          setExpandState((previousState) => !previousState);
        }}
        className={"searchbutton iconpos-" + props.direction}
        src={iconsIndex["search_icon"]}
        width="32px"
        height="32px"
        alt="Search"
      ></img>
    </div>
  );
}
