import analytics_icon from "./analytics_icon.svg"
import chat_send_icon from "./chat_send_icon.svg"
import qa_icon from "./qa_icon.svg"
import summarize_icon from "./summarize_icon.svg"
import search_icon from "./search_icon.svg"
import create_circle_icon from "./create_circle_icon.svg"
import arrow_back_icon from "./arrow_back_icon.svg"
import White_sidebar_deals_management from "./White_sidebar_deals_management.svg"
import White_sidebar_master_database_management from "./White_sidebar_master_database_management.svg"
import White_sidebar_user_management from "./White_sidebar_user_management.svg"
import White_surveylist_servicing         from "./White_surveylist_servicing.svg"
import White_surveylist_manufacturing     from "./White_surveylist_manufacturing.svg"
import White_surveylist_pharmacuticals    from "./White_surveylist_pharmacuticals.svg"
import White_surveylist_FMCGretail        from "./White_surveylist_FMCGretail.svg"
import White_surveylist_oil_gas           from "./White_surveylist_oil_gas.svg"
import White_surveylist_automotive        from "./White_surveylist_automotive.svg"
import White_surveylist_financialservices from "./White_surveylist_financialservices.svg"
import White_surveylist_mining            from "./White_surveylist_mining.svg"
import White_surveylist_power_energy      from "./White_surveylist_power_energy.svg"
import White_surveylist_technology        from "./White_surveylist_technology.svg"
import White_surveylist_construction from    "./White_surveylist_construction.svg"
import White_surveylist_realestate   from    "./White_surveylist_realestate.svg"
import White_surveylist_education    from    "./White_surveylist_education.svg"
import White_surveylist_textile      from    "./White_surveylist_textile.svg"
import White_surveylist_telecom      from    "./White_surveylist_telecom.svg"
import White_surveylist_chemicals    from    "./White_surveylist_chemicals.svg"
import White_surveylist_media        from    "./White_surveylist_media.svg"
import White_surveylist_slumpsale    from         "./White_surveylist_slumpsale.svg"
import White_surveylist_assetsale    from         "./White_surveylist_assetsale.svg"
import White_surveylist_sharepurchase       from  "./White_surveylist_sharepurchase.svg"
import White_surveylist_privatecompany      from  "./White_surveylist_privatecompany.svg"
import White_surveylist_publiclistedcompany from "./White_surveylist_publiclistedcompany.svg"
import White_surveylist_foreigncompany      from  "./White_surveylist_foreigncompany.svg"
import White_surveylist_partialintegration  from  "./White_surveylist_partialintegration.svg"
import White_surveylist_B2B                 from    "./White_surveylist_B2B.svg"
import White_surveylist_B2C                 from    "./White_surveylist_B2C.svg"
import White_surveylist_budget              from    "./White_surveylist_budget.svg"
import White_surveylist_employees           from    "./White_surveylist_employees.svg"
import White_surveylist_fullintegration     from    "./White_surveylist_fullintegration.svg" 
import White_surveylist_standalone from "./White_surveylist_standalone.svg"
import logout from "./logout.png"

export const iconsIndex = {
    "analytics_icon":analytics_icon,
    "chat_send_icon":chat_send_icon,
    "qa_icon":qa_icon,
    "summarize_icon":summarize_icon,
    "search_icon":search_icon,
    "create_circle_icon":create_circle_icon,
    "arrow_back_icon":arrow_back_icon,
    "white_sidebar_deals_management":White_sidebar_deals_management,
    "white_sidebar_master_database_management":White_sidebar_master_database_management,
    "white_sidebar_user_management":White_sidebar_user_management,
    "White_surveylist_servicing":White_surveylist_servicing,
    "White_surveylist_manufacturing":White_surveylist_manufacturing,
    "White_surveylist_pharmacuticals"    :White_surveylist_pharmacuticals,
    "White_surveylist_FMCGretail"        :White_surveylist_FMCGretail,
    "White_surveylist_oil&gas"           :White_surveylist_oil_gas,
    "White_surveylist_automotive"        :White_surveylist_automotive,
    "White_surveylist_financialservices" :White_surveylist_financialservices,
    "White_surveylist_mining"            :White_surveylist_mining,
    "White_surveylist_power&energy"      :White_surveylist_power_energy,
    "White_surveylist_technology"        :White_surveylist_technology,
    "White_surveylist_construction"  :    White_surveylist_construction,
    "White_surveylist_realestate"    :    White_surveylist_realestate,
    "White_surveylist_education"     :   White_surveylist_education,
    "White_surveylist_textile"       : White_surveylist_textile,
    "White_surveylist_telecom"       : White_surveylist_telecom,
    "White_surveylist_chemicals"     :   White_surveylist_chemicals,
    "White_surveylist_media"      :  White_surveylist_media,
    "White_surveylist_slumpsale"    :    White_surveylist_slumpsale,       
    "White_surveylist_assetsale"    :    White_surveylist_assetsale,
    "White_surveylist_sharepurchase":    White_surveylist_sharepurchase,
    "White_surveylist_privatecompany"       :White_surveylist_privatecompany      ,
    "White_surveylist_publiclistedcompany" :White_surveylist_publiclistedcompany,
    "White_surveylist_foreigncompany"       :White_surveylist_foreigncompany,
    "White_surveylist_partialintegration"   :White_surveylist_partialintegration,
    "White_surveylist_B2B":White_surveylist_B2B,
    "White_surveylist_B2C":White_surveylist_B2C,
    "White_surveylist_budget":White_surveylist_budget,
    "White_surveylist_employees":White_surveylist_employees,
    "White_surveylist_fullintegration"     :White_surveylist_fullintegration   ,      
    "White_surveylist_standalone"          :White_surveylist_standalone,
    //"White_surveylist_partialintegration": White_surveylist_partialintegration,
    "logout": logout,

}