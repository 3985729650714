import React from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import "./SideNavBar.css";
import { navigationData } from "../data/sidenavbar_data";
import HeaderBar from "./HeaderBar";
import ProtectedRoute from "./ProtectedRoute";
import { useUserAuth } from "../hooks/useUserAuth.js";
import { ToastContainer, toast } from "react-toastify";

function SideNavBar(props) {
  const { user, setUser } = useUserAuth();
  const username_parts = user.username.split(" ");
  const user_initials =
    username_parts[0][0].toUpperCase() + username_parts[1][0].toUpperCase();

  return (
    <>
      <HeaderBar
        app_title="M&A Transition Check Hub"
        username={user_initials}
      ></HeaderBar>
      <ToastContainer />
      <div className="sidebar">
        {navigationData.map((item, index, arr) => {
          return item.allowed_roles.includes("admin") ? (
            <div
              key={"sidebar_item_" + index}
              style={{
                bottom: arr.length - 1 == index ? "50px" : "0px",
                position: arr.length - 1 == index ? "absolute" : "relative",
              }}
            >
              <NavLink to={item.path}>
                {({ isActive, isPending }) => (
                  <img
                    src={item.icon}
                    title={item.title}
                    className={isActive ? "nav-icon-active" : "nav-icon"}
                  ></img>
                )}
              </NavLink>
            </div>
          ) : null;
        })}
      </div>

      <Routes>
        {navigationData.map((item, index) => {
          return (
            <Route
              key={"protected_navigation_item_" + index}
              element={<ProtectedRoute allowed_roles={item.allowed_roles} />}
            >
              <Route
                key={"navigation_item_" + index}
                path={item.path}
                element={<item.component />}
              >
                {item.subroutes
                  ? item.subroutes.map((item_sub, index_sub) => {
                      return (
                        <Route
                          key={"navigation_item_subroute" + index_sub}
                          path={item_sub.path}
                          element={<item_sub.component />}
                        />
                      );
                    })
                  : null}
                ;
              </Route>
            </Route>
          );
        })}
        ;
      </Routes>
    </>
  );
}

export default SideNavBar;
