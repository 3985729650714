import React from "react";
import { Outlet,useLocation,Navigate } from "react-router-dom";
import {useUserAuth} from "../hooks/useUserAuth.js";


const ProtectedRoute = ({allowed_roles}) =>{
  const {user,setUser} = useUserAuth();
  const location = useLocation();

  if(user.user_verified){
    if(allowed_roles.includes(user.user_type)){
      return (<Outlet/>)
    }
    else{
      return (<Navigate to = "/unauthorized" state = {{from:location}} replace/>)
    }
  }else{
    return (<Navigate to = "/login" state = {{from:location}} replace/>)
  }
}

export default ProtectedRoute;
