import React from "react";

function UnauthorizedPage() {

  return (
    <div className="page_content">
        <h1>Unauthorized,403</h1>
    </div>
  );
};

export default UnauthorizedPage;