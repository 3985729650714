import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};
const toastNotify = (toast_msg, type) => {
  if (type === "error") {
    toast.error(`${sanitizeInput(toast_msg)}`, toastOptions);
  } else if (type === "warning") {
    toast.warn(`${sanitizeInput(toast_msg)}`, toastOptions);
  } else if (type === "success") {
    toast.success(`${sanitizeInput(toast_msg)}`, toastOptions);
  }
  return <></>;
};
function sanitizeInput(input) {
  return input.replace(/<\/?[^>]+(>|$)/g, ""); // Basic HTML tag removal
}

export default toastNotify;
