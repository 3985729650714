import React, { useState } from 'react'
import { useSearchParams, Outlet, useLocation, Navigate } from 'react-router-dom';
import { useUserAuth } from '../hooks/useUserAuth'
import { useEffect } from 'react';
import { fetchData } from '../Utils';
import { RotatingLines } from 'react-loader-spinner';

// export default function LoginCheck() {
//     const { user, setUser } = useUserAuth();
//     const [verified, setVerified] = useState(false);
//     const location = useLocation();


//     var headers = {
//         'Content-Type': "application/json",
//         'Authorization': 'Bearer ', //user.user_access_token to be added here.
//         'User_Email': user.user_id,
//     }
//     const accessTokenFetchCallback = (data) => {
//         var user_type = data.user_role.toLowerCase();
//         var user_id = data.user_email;
//         var username = data.user_name;
//         var accessToken = data.user_access_token;
//         setUser((previousState) => {
//             return { ...previousState, user_verified: true, user_type: user_type, user_id: user_id, username: username, user_access_token: accessToken } // access token to be added here.
//         });
//         setVerified(true);
//     }

//     // Call authentication API through useEffect
//     //************* */
//     //1) user email(user id) 2) (user type) user_Otype 3) (access token)user_auth_code
//     //use stsa
//     //********************* */
//     //var code_verified = true;
//     //var user_type = "user";
//     //var user_id = 'hari.g.prasad.tpr@pwc.com';
//     //var username = 'Hari Prasad';
//     // if(user_auth_code == "1234"){
//     //     code_verified=true;
//     //     user_type = "user";
//     //     user_id = 'deeksha.rathore@pwc.com';
//     //     username = 'Deeksha Rathore';
//     // }else if(user_auth_code == "4321"){
//     //     code_verified=true;
//     //     user_type = "admin";
//     //     user_id = 'gaurav.gosain@pwc.com';
//     //     username = 'Gaurav Gosain';
//     // }

//     const [searchParams, setSearchParams] = useSearchParams();
//     const user_auth_code = searchParams.get("code");
//     useEffect(() => {
//         var payload = {
//             'User_Auth_Code': user_auth_code
//         }
//         var auth_url = fetchData("loginCheck", payload, "POST", headers, accessTokenFetchCallback);
//     }, []);


//     return (
//         <div>
//             {verified === true ? <Navigate to="../deals-ddv" state={{ from: location }} replace />
//                 : <div className='loader-dial'><div className='stop-overlay'></div>
//                     <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
//                 </div>}
//         </div>
//     )
// }





export default function LoginCheck() {
    const { user, setUser } = useUserAuth();
    const [verified, setVerified] = useState(false);
    const location = useLocation();


    var headers = {
        'Content-Type': "application/json",
        'Authorization': 'Bearer ', //user.user_access_token to be added here.
        'User_Email': user.user_id,
    }
    const accessTokenFetchCallback = (data) => {
        var user_type = data.user_role.toLowerCase();
        var user_id = data.user_email;
        var username = data.user_name;
        var accessToken = data.user_access_token;
        setUser((previousState) => {
            return { ...previousState, user_verified: true, user_type: user_type, user_id: user_id, username: username, user_access_token: accessToken } // access token to be added here.
        });
        setVerified(true);
    }

    // Call authentication API through useEffect
    //************* */
    //1) user email(user id) 2) (user type) user_Otype 3) (access token)user_auth_code
    //use stsa
    //********************* */
    //var code_verified = true;
    //var user_type = "user";
    //var user_id = 'hari.g.prasad.tpr@pwc.com';
    //var username = 'Hari Prasad';
    // if(user_auth_code == "1234"){
    //     code_verified=true;
    //     user_type = "user";
    //     user_id = 'deeksha.rathore@pwc.com';
    //     username = 'Deeksha Rathore';
    // }else if(user_auth_code == "4321"){
    //     code_verified=true;
    //     user_type = "admin";
    //     user_id = 'gaurav.gosain@pwc.com';
    //     username = 'Gaurav Gosain';
    // }

    const [searchParams, setSearchParams] = useSearchParams();
    const user_auth_code = searchParams.get("code");
    useEffect(() => {
        var payload = {
            'User_Auth_Code': user_auth_code
        }
        var auth_url = fetchData("loginCheck", payload, "POST", headers, accessTokenFetchCallback);
    }, []);


    return (
        <div>
            {verified === true ? <Navigate to="../deals-ddv/deals-management" state={{ from: location }} replace />
                : <div className='loader-dial'><div className='stop-overlay'></div>
                    <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                </div>}
        </div>
    )
}