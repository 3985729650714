import "./HeaderBar.css";
import pwc_icon from "../icons/pwc_dark.png";
import { Header, HeaderOptionItem, Avatar } from "@appkit4/react-components";

import { useUserAuth } from "../hooks/useUserAuth.js";
import { useIdleTimer } from "react-idle-timer";

function HeaderBar(props) {
  const { user, setUser } = useUserAuth();
  const handleOnIdle = (event) => {
    setUser((previousState) => {
      return {
        ...previousState,
        user_verified: false,
        user_role: null,
        user_type: null,
        user_id: null,
        username: null,
        user_access_token: null,
      }; // access token to be added here.
    });
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    // debounce: 500
  });

  return (
    <div className="header_bar">
      <Header
        type="transparent"
        compact={false}
        titleTemplate={() => props.app_title}
        userTemplate={() => (
          <Avatar
            label={props.username}
            role="button"
            disabled={false}
          ></Avatar>
        )}
      ></Header>
      {/* <div className="header_bar_left">
        <img src={pwc_icon} className="header_icon" alt="pwc"></img>
        <span className="header_separator">|</span>
        <span className="header_appname">{props.app_title}</span>
      </div>
      <div className="header_bar_right">
        <div className="header_user_menu">{props.username}</div>
      </div> */}
    </div>
  );
}

export default HeaderBar;
