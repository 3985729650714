import React from "react";
import "./Checklist.css";
import MultiBoxSelect from "./MultiBoxSelect.js";

export default function Checklist(props) {
  const item = props.item;
  const index = item.question_number;
  const checklist_response_obj = props.checklist_response_obj;
  const getChecklistChoices = props.getChecklistChoices;

  return (
    <div className="checklist">
      <div key={"checklist_question_" + index} className="checklist_question">
        <div
          className="checklist_question_title"
          key={"checklist_question_title" + index}
        >
          <h2 className="ap-typography-heading-s">
            <b> {"Q" + item.question_number + ". " + item.question_title}</b>
          </h2>
        </div>
        <div
          className="checklist_question_description"
          key={"checklist_question_description" + index}
        >
          <p>{item.question_description}</p>
        </div>
        <div className="checklist_question_options">
          <MultiBoxSelect
            key={"checklist_question_multiselect" + index}
            current_data={item}
            checklist_response_obj={checklist_response_obj}
            getChecklistChoices={getChecklistChoices}
          ></MultiBoxSelect>
        </div>
      </div>
    </div>
  );
}
