import React from "react";
import "./ActionModal.css";
import { Button } from "@appkit4/react-components";

export default function ActionModal(props) {
  const action_buttons = props.actions_obj;

  return (
    <div className="modal">
      <div className="stop-overlay"></div>
      <div className="modal-small">
        <div className="modal-heading">{props.heading}</div>
        <div className="modal-content">{props.msg}</div>
        <div className="modal-actions">
          {action_buttons.map((item, index) => {
            return (
              <Button
                kind={item.type}
                compact
                onClick={() => {
                  item.callback();
                }}
                style={{ margin: "10px" }}
              >
                {item.text}
              </Button>
            );
            // return <button style={item.style} onClick={()=>{item.callback()}}>{item.text}</button>
          })}
        </div>
      </div>
    </div>
  );
}
