import React from 'react';
import home_icon from '../icons/home_icon.svg';
import qa_icon from '../icons/qa_icon.svg';
import manage_accounts_icon from '../icons/manage_accounts_icon.svg';
import summarize_icon from '../icons/summarize_icon.svg';
import HomePage from '../pages/Home';
import UserManagementPage from '../pages/UserManagement';
import SummarizationPage from '../pages/Summarization';
import Logout from '../pages/Logout';
import { iconsIndex } from '../icons/icons_index';
import DatabaseManagement from '../pages/DatabaseManagement';

export const navigationData = [
    {
        title: 'Deals Management',
        path: 'deals-management',
        icon: iconsIndex["white_sidebar_deals_management"],
        cName: 'nav-text',
        component: HomePage,
        protected: true,
        allowed_roles: ["user", "admin"],
    },
    //{
    //  title: 'Database Management',
    //  path: 'master-database-management',
    //  icon: iconsIndex["white_sidebar_master_database_management"],
    //  cName: 'nav-text',
    //  component: SummarizationPage,
    //  protected:true,
    //  allowed_roles : ["admin"],
    //},
    {
        title: 'User Management',
        path: 'user-management',
        icon: iconsIndex["white_sidebar_user_management"],
        cName: 'nav-text',
        component: UserManagementPage,
        protected: true,
        allowed_roles: ["admin"],
    },
    {
        title: 'Database Management',
        path: 'database-management-master',
        icon: iconsIndex["white_sidebar_master_database_management"],
        cName: 'nav-text',
        component: DatabaseManagement,
        protected: true,
        allowed_roles: ["admin"],
    },
    {
        title: 'Logout',
        path: 'logout',
        icon: iconsIndex["logout"],
        cName: 'nav-text',
        component: Logout,
        protected: true,
        allowed_roles: ["user", "admin"],
  }
];