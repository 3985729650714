import React from 'react';

export const checklistData = [

    {   question_number: "1",

        question_title:"What is the Nature of Target's Business?",

        question_description:"Select 1",

        question_options:[

            {option_number:1,option_icon:"White_surveylist_servicing",option_text:"Services" },

            {option_number:2,option_icon:"White_surveylist_manufacturing",option_text:"Manufacturing" },

            {option_number:3,option_text:"Both" }

        ],

        allowed_choices:1,
        minimum_choices:1,
    },

    {   question_number: "2",

        question_title:"What is the Target industry?",

        question_description:"Select 1 or more",

        question_options:[

            {option_number:1,option_icon:"White_surveylist_pharmacuticals",option_text:"Pharmaceuticals" },

            {option_number:2,option_icon:"White_surveylist_FMCGretail",option_text:"FMCG/Retail" },

            {option_number:3,option_icon:"White_surveylist_oil&gas",option_text:"Oil & Gas" },

            {option_number:4,option_icon:"White_surveylist_automotive",option_text:"Automotive" },

            {option_number:5,option_icon:"White_surveylist_financialservices",option_text:"Financial Services" },

            {option_number:6,option_icon:"White_surveylist_mining",option_text:"Mining" },

            {option_number:7,option_icon:"White_surveylist_power&energy",option_text:"Power & Energy" },

            {option_number:8,option_icon:"White_surveylist_technology",option_text:"Technology" },

            {option_number:9,option_icon:"White_surveylist_construction",option_text:"Construction" },

            {option_number:10,option_icon:"White_surveylist_realestate",option_text:"Real Estate" },

            {option_number:11,option_icon:"White_surveylist_education",option_text:"Education" },
        
            {option_number:12,option_icon:"White_surveylist_textile",option_text:"Textile" },

            {option_number:13,option_icon:"White_surveylist_telecom",option_text:"Telecom" },

            {option_number:14,option_icon:"White_surveylist_chemicals",option_text:"Chemicals" },
        
            {option_number:15,option_icon:"White_surveylist_media",option_text:"Media" },
        ],

        allowed_choices:15,
        minimum_choices:1,

    },

    {   question_number: "3",

        question_title:"What is the Type of Deal?",

        question_description:"Select 1 or more",

        question_options:[

            {option_number:1,option_icon:"White_surveylist_slumpsale",option_text:"Slump Sale" },

            {option_number:2,option_icon:"White_surveylist_assetsale",option_text:"Asset Sale" },

            {option_number:3,option_icon:"White_urveylist_realestate",option_text:"Internal Restructuring / Inter Company Sale" },

            {option_number:4,option_icon:"White_surveylist_sharepurchase",option_text:"Share Purchase" },

        ],

        allowed_choices:4,
        minimum_choices:1,
    },

    {   question_number: "4",

        question_title:"What is the Legal Entity Type of the Target?",

        question_description:"Select 1",

        question_options:[

            {option_number:1,option_icon:"White_surveylist_privatecompany",option_text:"Private Company" },

            {option_number:2,option_icon:"White_surveylist_privatelistedcompany",option_text:"Public Listed Company in India" },

            {option_number:3,option_icon:"White_surveylist_foreigncompany",option_text:"Foreign Company (Private/Public)" },

            {option_number:4,option_icon:"Green_surveylist_partialintegration",option_text:"LLP / Partnership Firm" },

        ],

        allowed_choices:1,
        minimum_choices:1,
    },

    {   question_number: "5",

        question_title:"What is the Target's Business Model?",

        question_description:"Select 1",

        question_options:[

            {option_number:1,option_icon:"White_surveylist_B2B",option_text:"Business(B2B)" },
    
            {option_number:2,option_icon:"White_surveylist_B2C",option_text:"Customer(B2C)" },
    
            {option_number:3,option_text:"Mix of Both" }
    
        ],
    

        allowed_choices:1,
        minimum_choices:1,

    },


    {   question_number: "6",

        question_title:"What is the size of Deal/Transaction?",

        question_description:"Select 1",

        question_options:[

            {option_number:1,option_icon:"White_surveylist_budget",option_text:"<USD 100M" },
    
            {option_number:2,option_icon:"White_surveylist_budget",option_text:"USD 100M-250M" },
    
            {option_number:3,option_icon:"White_surveylist_budget",option_text:"USD 250M-500M" },
    
            {option_number:4,option_icon:"White_surveylist_budget",option_text:"USD 500M-1B" },
        
            {option_number:5,option_icon:"White_surveylist_budget",option_text:">USD 1B" }
        ],

        allowed_choices:1,
        minimum_choices:1,

    },


    {   question_number: "7",

        question_title:"How many employees does the target business have?",

        question_description:"Select 1",

        question_options:[

            {option_number:1,option_icon:"White_surveylist_employees",option_text:"100" },
    
            {option_number:2,option_icon:"White_surveylist_employees",option_text:"100-250" },
    
            {option_number:3,option_icon:"White_surveylist_employees",option_text:"250-500" },
    
            {option_number:4,option_icon:"White_surveylist_employees",option_text:"500-1000" },
        
            {option_number:5,option_icon:"White_surveylist_employees",option_text:"1000+" }
        ],

        allowed_choices:1,
        minimum_choices:1,

    },



    {   question_number: "8",

        question_title:"What is the Integration Strategy planned to be applied by Buyer?",

        question_description:"Select 1",

        question_options:[

            {option_number:1,option_icon:"White_surveylist_fullintegration",option_text:"Full Integration" },
    
            {option_number:2,option_icon:"White_surveylist_partialintegration",option_text:"Partial Integration" },
    
            {option_number:3,option_icon:"White_surveylist_standalone",option_text:"Stand-Alone" }
    
        ],

        allowed_choices:1,
        minimum_choices:1,

    },

];

