import React from 'react';

export const menubarData = [
    {   stage_number: "1",
        stage_title:"Basic Details",
    },
    {   stage_number: "2",
        stage_title:"Survey",
    },
    {   stage_number: "3",
        stage_title:"Checklist",
    },
];