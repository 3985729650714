import React from "react";
import "./BoxSelect.css";
import { convertHexToRGBA } from "../Utils";

export default function BoxSelect(props) {
  const question_number = props.question_number;

  const box_style = {
    backgroundColor: props.checked ? props.backgroundColor : "white",
    color: props.checked ? "white" : props.color,
    borderRadius: "5px",
    border: props.border,
  };

  return (
    <div
      style={box_style}
      className="box_select"
      onClick={() => {
        props.changeState(question_number, [props.index], true);
      }}
    >
      <img
        src={props.icon}
        style={{
          backgroundColor: convertHexToRGBA(props.backgroundColor, 0.7),
          margin: "10px",
          padding: "5px",
          width: "30px",
        }}
      ></img>
      <span
        style={{
          fontSize: "14px",
          marginLeft: "5px",
          marginRight: "5px",
          fontWeight: "bold",
        }}
      >
        {props.text}
      </span>
    </div>
  );
}
