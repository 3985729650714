import React from 'react'
import "./StepProgressBar.css";

export default function StepProgressBar(props) {
   
  const steps = props.steps;
  const steps_hint = props.steps_hints;
  const steps_status = props.steps_status;
  const save_and_next = props.save_and_next;
  const current_question = props.current_question;
  const goto_question = props.goto_question;

    return (
        <div className='step_status_bar'>
            {current_question>0?<button onClick = {()=>{return goto_question(current_question-1)}}className = "step_status_bar_ui_button" style ={{left:"5%",position:"absolute",  borderRadius: "4px", padding: "8px 20px", fontSize: "16px", fontWeight: "bold"}}>Previous</button>:null}
            {steps.map((item,index)=>{
                return (<div key = {"step_progress_bar_"+index} onClick = {() => {goto_question(index)}} style={{display:"flex",justifyContent:"center",alignItems:"center"}}> 
                            <div title = {steps_hint[index]} className={steps_status[index]?"step_bullet_active":"step_bullet"}>
                                {item}
                            </div>
                            {index<(steps.length-1)?<div className={steps_status[index]?"step_link_active":"step_link"}></div>:null}
                        </div>
                    )
                })
            }
            <button className = "step_status_bar_ui_button" style ={{right:"5%",position:"absolute", borderRadius: "4px", padding: "8px 20px", fontSize: "16px", fontWeight: "bold"}} onClick = {()=>{save_and_next()}}>{current_question<(steps_status.length-1)?"Save and Next":"Save and Create Checklist"}</button>
        </div>    
  )
}

