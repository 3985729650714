import React from "react";

import DataTable from "react-data-table-component";
import { ReactComponent as ExpandMoreIcon } from "../icons/expand_more_icon.svg";

// A super simple expandable component.
const ExpandedComponent = ({ data }) => (
  <pre>{JSON.stringify(data, null, 2)}</pre>
);

// const sortIcon = <ArrowDownward />;
// const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
function DataTbl(props) {
  return (
    <div className={props.classname}>
      <DataTable
        pagination
        // selectableRowsComponent={Checkbox}
        columns={props.columns}
        // selectableRowsComponentProps={selectProps}
        data={props.data}
        // sortIcon={sortIcon}
        dense
        sortIcon={<ExpandMoreIcon></ExpandMoreIcon>}
        paginationPerPage={props.pagination_per_page}
        paginationRowsPerPageOptions={props.pagination_options}
        fixedHeader
        fixedHeaderScrollHeight="600px"
        // conditionalRowStyles={conditionalRowStyles}
        {...props}
      />
    </div>
  );
}

export default DataTbl;
