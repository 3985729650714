import React from 'react'
import "./MenuBar.css";
import { menubarData } from '../data/menubar_data';

export default function MenuBar(props) {

    const current_stage = props.status; 
    const stage_classes = Array(menubarData.length).fill(null);
    for(var i=0;i<menubarData.length;i++){
        if(i<current_stage){
            stage_classes[i] = "complete";
        }else if(i==current_stage){
            stage_classes[i] = "active";
        }else{
            stage_classes[i] = "inactive";
        }
    }

    return (
    <div className='menubar'>
        <div className='menubar_stage'>
            {menubarData.map((item,index)=>{
                return (
                    <div key = {"menubar_stage_item_" + index} className='menubar_stage_item'>
                        <div className={("menubar_stage_item_topbar " + stage_classes[index])}></div>
                        <div className='menubar_stage_item_name'>
                            <span className={("menubar_stage_round " + "round-" + stage_classes[index])}>{index+1}</span>
                            <span className='menubar_stage_item_name_title'>{item.stage_title}</span>
                        </div>
                    </div>
                )
            })
            }
        </div>
    </div>
    )
}
