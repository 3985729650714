import React, { useEffect, useState } from "react";
import "./Login.css";
import { fetchData } from "../Utils";
import { Button, Panel } from "@appkit4/react-components";
import Logo from "../assets/PwC_logo_simplified.svg";

function LoginPage() {
  const [AuthURL, setAuthUrl] = useState(" ");
  const authUrlFetchCallback = (data) => {
    // window.location = data.authURL;
    setAuthUrl(data.authURL);
  };

  useEffect(() => {
    var auth_url = fetchData(
      "get_auth_url",
      {},
      "POST",
      {},
      authUrlFetchCallback
    );
  }, []);

  return (
    <div style={{ background: "#fff", height: "100vh" }}>
      <div
        style={{
          padding: "22px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={Logo} width={70}></img>
        <div
          style={{
            fontSize: "1.5rem",
            paddingLeft: "6px",
            fontWeight: "bold",
            color: "black",
            paddingTop: "6px",
            marginLeft: "5px",
          }}
        >
          M&A Transition Check Hub
        </div>
      </div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: "2%",
            boxShadow: "rgb(223, 230, 245) 0px 0px 15px",
            borderRadius: "10px",
            paddingLeft: "0px",
            paddingRight: "0px",
            width: "450px",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              color: "#7d7d7d",
              paddingBottom: "50px",
              fontSize: "1.8em",
              marginTop: "40px",
            }}
          >
            M&A Transition Check Hub
          </h1>
          <div
            style={{ margin: "10%", display: "flex", justifyContent: "center" }}
          >
            <Button
              kind="primary"
              style={{
                width: "230px",
                fontWeight: "bold",
              }}
              onClick={() => window.open(AuthURL, "_self")}
            >
              Sign in with PwC email
            </Button>
          </div>
        </div>
        <div item xs={1} sm={2} md={4}></div>
      </div>
    </div>
  );
}

export default LoginPage;
