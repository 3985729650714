import React, { useEffect } from "react";
import "./DatabaseManagement.css";
import { iconsIndex } from "../icons/icons_index";
import DataTbl from "../components/DataTbl";
import { useState } from "react";
import Select from "react-select";
import ActionModal from "../components/ActionModal";
import moment from "moment";
import toastNotify from "../components/Toast.js";
import { ToastContainer, toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import { fetchData } from "../Utils";
import { useUserAuth } from "../hooks/useUserAuth.js";
import "../GlobalVariables";
import Slider from "react-slick";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import FinancialServiceLogo from "../icons/Green_surveylist_financialservices.svg";

export default function DatabaseManagementPage() {
  const { user, setUser } = useUserAuth();
  //const [users_data, setUsersData] = useState([]);

  const [dataFetched, setDataFetched] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const [isSurvey, setIsSurvey] = useState(true);
  const [isCheckList, setIsCheckList] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const [surveyDataFetched, setSurveyDataFetched] = useState([]);
  const [surveyDataResponse, setSurveyDataResponse] = useState([]);

  const [checklistDataFetched, setChecklistDataFetched] = useState([]);
  const [checklistDataResponse, setChecklistDataResponse] = useState([]);

  //const [dataFetchedUser, setDataFetchedUser] = useState([]);
  const [checklistUploadUser, setChecklistUploadUser] = useState("");

  const [optionValue, setOptionValue] = useState([]);

  const tabs = [];
  const [tab, setTab] = useState([]);
  const [tabData, setTabData] = useState([]);

  const data = [
    {
      task_no: 1,
      task_header: "What is the Nature of Targets Business ?",
      modified_by: "Hari",
      modified_date: "01 March 2023",
      response: ["Lorem Ipsum", "Lorem Ipsum"],
    },
  ];

  // Call create user API
  const confirmFileUploaded = (response) => {
    setShowLoader(false);
    if (response["status"] === 200 && response["errors"] === false) {
      toastNotify(response["msg"], "success", {
        autoClose: 20,
      });
    } else if (response["status"] === 200 && response["errors"] === true) {
      toastNotify(response["msg"], "error", {
        autoClose: 20,
      });
    } else if (response["status"] === 500) {
      // alert("Template not found please upload again!")
      toastNotify("Template not found please upload again!", "error", {
        autoClose: 20,
      });
    }
  };

  function fileLoadedSuccess() {
    toastNotify("File uploaded successfully - can scan now!", "success", {
      autoClose: 20,
    });
  }

  function fileUploadFail(e) {
    toastNotify("File upload error - please try again!", "success", {
      autoClose: 2000,
    });
  }

  const uploadFile = async (e) => {
    var validExts = new Array(".xlsx", ".xls");
    var fileExt = e.target.files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf("."));
    if (validExts.indexOf(fileExt) < 0) {
      alert(
        "Invalid file selected, valid files are of " +
          validExts.toString() +
          " types."
      );
      return false;
    }
    // else return true;
    setShowLoader(true);
    e.preventDefault();

    var file_obj = {
      filename: "",
      mimeType: "",
      data: "",
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var upFiles = e.target.files[0];
    var reader = new FileReader();
    file_obj.filename = upFiles.name;
    file_obj.mimeType = upFiles.type;
    reader.onload = async (e) => {
      file_obj.data = e.target.result.split(",")[1];
      var payload = {
        // "user_email": userEmailValue || userEmailValueExisting,
        user_email: "hari.g.prasad.tpr@pwc.com",
        file_name: upFiles.name,
        file_obj: file_obj.data,
        timestamp: Date.now(),
      };
      var response = await fetchData(
        "checklist_upload",
        payload,
        "POST",
        headers,
        confirmFileUploaded
      );
      if (response != undefined) {
        setSurveyDataFetched(response.survey);
        setSurveyDataResponse(response.survey[0].options);
        setChecklistDataFetched(response.data);
        setChecklistDataResponse(response.data[0].data[0].response);
        let tempArr = [...optionValue];
        tempArr.unshift(response.version_id);
        setOptionValue(tempArr);
        document.getElementById("fileId").value = null;
        let tempData = [...dataFetched];
        tempData.unshift({
          survey_data: response.survey,
          checklist_data: response.data,
          version_id: response.version_id,
        });
        setDataFetched(tempData);
      }
    };
    reader.addEventListener("loadend", fileLoadedSuccess);
    reader.addEventListener("error", fileUploadFail);
    reader.readAsDataURL(upFiles);
  };

  const surveyanswer = (response) => {
    setSurveyDataResponse(response);
  };

  const checklistanswer = (response) => {
    setChecklistDataResponse(response);
  };

  const toggleSurveyCheckList = (e) => {
    if (e == "survey") {
      setIsSurvey(true);
      setIsCheckList(false);
    } else {
      setIsSurvey(false);
      setIsCheckList(true);
    }
  };

  useEffect(() => {
    var header = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234", //user.user_access_token to be added here.
      User_Email: user.user_id,
    };
    var headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1234",
    };
    var payload = {
      user_email: user.user_id,
      timestamp: Date.now(),
    };
    // show damroo here. Loader.
    setShowLoader(true);
    var checklist_response = fetchData(
      "fetch_checklist_version",
      payload,
      "POST",
      header,
      checklistFetchCallback
    );
    // var user_response = fetchData("get_all_registered_users",{},"POST",headers,userFetchCallback);
  }, []);

  const checklistFetchCallback = (data) => {
    if (data != null && data != undefined && data.data != undefined) {
      //checklistUploadUser = data.user;
      setChecklistUploadUser(data.user);
      setDataFetched(data.data);
      setIsDataFetched(true);
      setIsSurvey(true);

      var temp = Array.isArray(data.data[0].survey_data)
        ? data.data[0].survey_data
        : JSON.parse(data.data[0].survey_data);
      setSurveyDataFetched(temp);
      setSurveyDataResponse(temp[0].options);
      var temp1 = Array.isArray(data.data[0].checklist_data)
        ? data.data[0].checklist_data
        : JSON.parse(data.data[0].checklist_data);
      setChecklistDataFetched(temp1);
      //var temp2 = JSON.parse(data.user);
      //setDataFetchedUser(temp2);
      setChecklistDataResponse(temp1[0].data[0].response);
      setShowLoader(false);
      let arr = [];
      data.data.map((item) => {
        arr.push(item.version_id);
      });
      setOptionValue(arr);

      let tab_data = [];
      temp1.map((item) => {
        tab_data.push({ name: item.sheet_name, img: FinancialServiceLogo });
      });

      tab_data.forEach(({ name, img }) => {
        tabs.push(
          <Tab className="category-tab" key={name}>
            <img src={img} alt={name} height="32" width="32" />
            <span>{name}</span>
          </Tab>
        );
      });
      setTabData(tab_data);
      setTab(tabs);
    } else {
      setShowLoader(false);
      toastNotify("Users update failed!", "error", { autoClose: 30 });
    }
  };

  const onSelectChange = (event) => {
    const arr = dataFetched.filter((item) => {
      return item.version_id == event.target.value;
    });
    if (Array.isArray(arr[0].survey_data)) {
      setSurveyDataFetched(arr[0].survey_data);
      setSurveyDataResponse(arr[0].survey_data[0].options);
    } else {
      let survey_data = JSON.parse(arr[0].survey_data);
      setSurveyDataFetched(survey_data);
      setSurveyDataResponse(survey_data[0].options);
    }

    if (Array.isArray(arr[0].checklist_data)) {
      setChecklistDataFetched(arr[0].checklist_data);
      setChecklistDataResponse(arr[0].checklist_data[0].data[0].response);
    } else {
      let checklist_data = JSON.parse(arr[0].checklist_data);
      setChecklistDataFetched(checklist_data);
      setChecklistDataResponse(checklist_data[0].data[0].response);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
  };

  if (isSurvey) {
    return isDataFetched ? (
      <div className="page_content">
        <div className="menubar_simple">
          <div
            className="menubar_title"
            style={{ display: "flex", alignItems: "center" }}
          >
            Database Management
            <select
              className="round"
              style={{ margin: "0px 30px" }}
              onChange={(e) => onSelectChange(e)}
            >
              <option defaultValue disabled>
                Select Version
              </option>
              {optionValue.map((value, index) => {
                return (
                  <option style={{ padding: "10px" }} value={value}>
                    <span>Version {value}</span>
                  </option>
                );
              })}
            </select>
            <div
              class="upload-btn-wrapper"
              style={{ margin: "0px 30px 0px 0px" }}
            >
              <button class="file-btn">Upload file</button>
              <input
                type="file"
                name="myfile"
                id="fileId"
                onChange={(e) => uploadFile(e)}
                accept=".xls,.xlsx"
                // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>
          </div>

          {/* <input type="file" id="fileId" onChange={(e) => uploadFile(e)} /> */}

          <div className="menubar_actions">
            <span
              className="survey"
              onClick={(e) => {
                toggleSurveyCheckList("survey");
              }}
              style={{
                padding: isSurvey ? "10px" : "0px",
                background: isSurvey
                  ? "#289D8E 0% 0% no-repeat padding-box"
                  : "#ffffff",
                color: isSurvey ? "#FFFFFF" : "black",
                width: isSurvey ? "140px" : "90px",
              }}
            >
              Survey
            </span>
            <span
              className="checkl"
              onClick={(e) => {
                toggleSurveyCheckList("checklist");
              }}
              style={{
                padding: isCheckList ? "10px" : "0px",
                background: isCheckList
                  ? "#289D8E 0% 0% no-repeat padding-box"
                  : "#ffffff",
                color: isCheckList ? "#FFFFFF" : "black",
                width: isCheckList ? "140px" : "90px",
              }}
            >
              Checklist
            </span>
          </div>
        </div>

        <div className="content-body">
          <div className="questions overflow-y">
            <div className="questions-title">
              <span className="title-name">Questions</span>
              <span
                className="title-name"
                style={{ float: "right", marginRight: "10%" }}
              >
                Modified by
              </span>
            </div>
            <div className="questions-body">
              {surveyDataFetched.map((item, index) => {
                return (
                  <div
                    className="question-row"
                    onClick={(e) => {
                      surveyanswer(item.options);
                    }}
                  >
                    <div className="question-numbers">
                      <span>
                        {item.question_no ? item.question_no : item.question}
                      </span>
                    </div>
                    <span className="question-text">
                      {" "}
                      {item.question_no ? item.question : item.description}{" "}
                    </span>
                    <span className="question-modified-date">
                      <div className="question-modified">
                        {checklistUploadUser}{" "}
                      </div>
                      {/*<span className='question-date'>12 Dec 2012</span>*/}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="response overflow-y">
            <div className="response-title">
              <span className="title-name">Response</span>
            </div>
            <div className="response-body">
              {surveyDataResponse.length ? (
                surveyDataResponse.map((item, index) => {
                  return (
                    <div className="response-row">
                      <span className="opt-num">Option {index + 1}</span>
                      <span className="opt-text">{item}</span>
                    </div>
                  );
                })
              ) : surveyDataFetched.length ? (
                <div className="no-record-display">
                  There are no records to display
                </div>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
        </div>
        {showLoader && (
          <div className="loader-dial">
            <div className="stop-overlay"></div>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
        <ToastContainer autoClose={20} />
      </div>
    ) : (
      <div className="loader-dial">
        <div className="stop-overlay"></div>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    );
  } else if (isCheckList) {
    return isDataFetched ? (
      <div className="page_content">
        <div className="menubar_simple">
          <div
            className="menubar_title"
            style={{ display: "flex", alignItems: "center" }}
          >
            Database Management
            <select
              className="round"
              style={{ margin: "0px 30px" }}
              onChange={(e) => onSelectChange(e)}
            >
              <option defaultValue disabled>
                Select Version
              </option>
              {optionValue.map((value, index) => {
                return (
                  <option style={{ padding: "10px" }} value={value}>
                    <span>Version {value}</span>
                  </option>
                );
              })}
            </select>
            <div
              class="upload-btn-wrapper"
              style={{ margin: "0px 30px 0px 0px" }}
            >
              <button class="file-btn">Upload file</button>
              <input
                type="file"
                name="myfile"
                id="fileId"
                onChange={(e) => uploadFile(e)}
              />
            </div>
          </div>

          {/* <input type="file" id="fileId" onChange={(e) => uploadFile(e)} /> */}

          <div className="menubar_actions">
            <span
              className="survey"
              onClick={(e) => {
                toggleSurveyCheckList("survey");
              }}
              style={{
                padding: isSurvey ? "10px" : "0px",
                background: isSurvey
                  ? "#289D8E 0% 0% no-repeat padding-box"
                  : "#ffffff",
                color: isSurvey ? "#FFFFFF" : "black",
                width: isSurvey ? "140px" : "90px",
              }}
            >
              Survey
            </span>
            <span
              className="checkl"
              onClick={(e) => {
                toggleSurveyCheckList("checklist");
              }}
              style={{
                padding: isCheckList ? "10px" : "0px",
                background: isCheckList
                  ? "#289D8E 0% 0% no-repeat padding-box"
                  : "#ffffff",
                color: isCheckList ? "#FFFFFF" : "black",
                width: isCheckList ? "140px" : "90px",
              }}
            >
              Checklist
            </span>
          </div>
        </div>

        <div className="tab-container">
          <Tabs className="Tabs" variant="scrollable" scrollButtons={true}>
            <TabList
              className="Tab-list"
              hideNavBtns={false}
              style={{ margin: "20px !important" }}
            >
              {tab}
            </TabList>

            {checklistDataFetched.map((element, index) => {
              return (
                <TabPanel>
                  <div className="tab-data">
                    <div className="questions overflow-y">
                      <div className="questions-title">
                        <span className="title-name">Questions</span>
                        <span
                          className="title-name"
                          style={{ float: "right", marginRight: "10%" }}
                        >
                          Modified by
                        </span>
                      </div>
                      <div className="question-body-checklist">
                        {element.data.map((item, index) => {
                          return (
                            <div
                              className="question-row"
                              onClick={(e) => {
                                checklistanswer(item.response);
                              }}
                            >
                              <div className="question-numbers-checklist">
                                <span>{item.task_no.toFixed(2)}</span>
                              </div>
                              <span className="question-text">
                                {" "}
                                {item.task_header}{" "}
                              </span>
                              <span className="question-modified-date">
                                <div className="question-modified">
                                  {checklistUploadUser}{" "}
                                </div>
                                {/*<span className='question-date'>12 Mar 2012</span>*/}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="response overflow-y">
                      <div className="response-title">
                        <span className="title-name">Response</span>
                      </div>
                      <div className="response-body">
                        {checklistDataResponse.length ? (
                          <div>
                            <div>
                              <div className="response-form">
                                <div className="response-form-set">
                                  <label>Integration Strategy</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[0][
                                        "Integration Strategy"
                                      ]
                                    }
                                    disabled
                                  ></input>
                                </div>
                                <div className="response-form-set">
                                  <label>Related Questions</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[0]["Related ques"]
                                    }
                                    disabled
                                  ></input>
                                </div>
                              </div>
                              <hr></hr>
                            </div>
                            <div>
                              <div className="response-form">
                                <div className="response-form-set">
                                  <label>Product Type</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[1]["Product Type"]
                                    }
                                    disabled
                                  ></input>
                                </div>
                                <div className="response-form-set">
                                  <label>Related Questions</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[1]["Related ques"]
                                    }
                                    disabled
                                  ></input>
                                </div>
                              </div>
                              <hr></hr>
                            </div>
                            <div>
                              <div className="response-form">
                                <div className="response-form-set">
                                  <label>Industry</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={checklistDataResponse[2]["Industry"]}
                                    disabled
                                  ></input>
                                </div>
                                <div className="response-form-set">
                                  <label>Related Questions</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[2]["Related ques"]
                                    }
                                    disabled
                                  ></input>
                                </div>
                              </div>
                              <hr></hr>
                            </div>
                            <div>
                              <div className="response-form">
                                <div className="response-form-set">
                                  <label>Deal Type</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[3]["Deal Type"]
                                    }
                                    disabled
                                  ></input>
                                </div>
                                <div className="response-form-set">
                                  <label>Related Questions</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[3]["Related ques"]
                                    }
                                    disabled
                                  ></input>
                                </div>
                              </div>
                              <hr></hr>
                            </div>
                            <div>
                              <div className="response-form">
                                <div className="response-form-set">
                                  <label>Type of CO</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[4]["Type of CO"]
                                    }
                                    disabled
                                  ></input>
                                </div>
                                <div className="response-form-set">
                                  <label>Related Questions</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[4]["Related ques"]
                                    }
                                    disabled
                                  ></input>
                                </div>
                              </div>
                              <hr></hr>
                            </div>
                            <div>
                              <div className="response-form">
                                <div className="response-form-set">
                                  <label>Type of Customer</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[5][
                                        "Type of Customer"
                                      ]
                                    }
                                    disabled
                                  ></input>
                                </div>
                                <div className="response-form-set">
                                  <label>Related Questions</label>
                                  <br></br>
                                  <input
                                    className="response-form-feild"
                                    type="text"
                                    value={
                                      checklistDataResponse[5]["Related ques"]
                                    }
                                    disabled
                                  ></input>
                                </div>
                              </div>
                              <hr></hr>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </Tabs>
        </div>
        {showLoader && (
          <div className="loader-dial">
            <div className="stop-overlay"></div>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
        <ToastContainer autoClose={20} />
      </div>
    ) : (
      <div className="loader-dial">
        <div className="stop-overlay"></div>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    );
  } else {
    <div>No records</div>;
  }
}
