import React from "react";
import { useState,useEffect } from "react";
import "./Survey.css";
import '../GlobalVariables'
import Checklist from "./Checklist";
import StepProgressBar from "./StepProgressBar";
import MenuBar from "./MenuBar";
import {checklistData} from "../data/checklist_data.js";
import toastNotify from '../components/Toast.js';
import { ToastContainer, toast } from 'react-toastify';

function Survey(props) {

  // Create checklist level state
  const current_question = props.current_question;
  const updateCurrentQuestion = props.updateCurrentQuestion;
  const steps_status_state = props.steps_status_state;
  const updateStepStatus = props.updateStepStatus;
  const checklist_response_obj = props.checklist_response_obj;
  const updateChecklistChoices = props.updateChecklistChoices;
  const checklist_static_data = props.checklist_static_data;

  // State management functions
  const saveAndNext = props.saveAndNext;
  const gotoQuestion = props.gotoQuestion;
  const getChecklistChoices = props.getChecklistChoices;  

  return (
    <div className="survey_content">
        <Checklist item = {checklistData[current_question]} checklist_response_obj = {checklist_response_obj} getChecklistChoices = {getChecklistChoices}></Checklist>
        <StepProgressBar steps={checklist_static_data.steps} steps_hints={checklist_static_data.steps_hints} steps_status={steps_status_state} save_and_next = {saveAndNext} current_question={current_question} goto_question = {gotoQuestion}></StepProgressBar>
        <ToastContainer />
    </div>
  );
};

export default Survey;