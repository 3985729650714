import "./App.css";
import LoginPage from "./pages/Login";
import LoginCheck from "./components/LoginCheck";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserAuthContext } from "./context/UserAuthContext";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SideNavBar from "./components/SideNavBar";
import Layout from "./components/Layout";
import UnauthorizedPage from "./pages/Unauthorized";
import { ToastContainer, toast } from "react-toastify";

function App() {
  return (
    <div className="App" data-mode="light">
      <BrowserRouter>
        <UserAuthContext>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logincheck" element={<LoginCheck />} />
              <Route path="/unauthorized" element={<UnauthorizedPage />} />

              <Route
                element={<ProtectedRoute allowed_roles={["user", "admin"]} />}
              >
                <Route path="/deals-ddv/*" element={<SideNavBar />} />
              </Route>

              <Route path="/" element={<Navigate to="/login" replace />} />
            </Route>
          </Routes>
        </UserAuthContext>
      </BrowserRouter>
    </div>
  );
}

export default App;

/*
                  {NavigationData.map((item,index) => {
                    return (                  
                      <Route key={("protected_navigation_item_" + index)} element={<ProtectedRoute/>}>
                        <Route key={("navigation_item_" + index)} path={item.path} element={<item.component/>} />
                      </Route>
                    )
                  })};
*/
