// Manage global variable for API calls
global.global_state_variables = {}
global.users_data = {}

// Variables for Admin login
/*
global_state_variables["checklist_data"] = {} // Checklist questions for any new deal
global_state_variables["deals_data"] = {} // All existing projects data for user
global_state_variables["user_addition"] = {} // Latest data for user addition - either through user management or admin portal
global.user_addition = {}
global.user_deletion = {}
global.deal_addition = {}
global.deal_deletion = {}
*/