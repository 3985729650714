import React from "react";
import { useState } from "react";
import "./CreateUserModal.css";
import { Input, Select, Button } from "@appkit4/react-components";

export default function CreateUserModal(props) {
  // Form params for new user
  const user_info = props.user_info;
  const setUserInfo = props.setUserInfo;
  const cancel = props.cancel;
  const submit = props.submit;

  const [userType, setUserType] = useState(null);
  const userTypeOptions = [
    { value: "User", label: "User" },
    { value: "Admin", label: "Admin" },
  ];
  const userTypeChange = (selectedOption) => {
    setUserType(selectedOption);
    setUserInfo(selectedOption, "user_type");
  };
  const selectCSSStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#ffffff",
      fontSize: "14px",
      width: "200px",
      height: "20px",
      outline: "none",
      border: "none",
      boxShadow: "none",
      minHeight: "20px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f0f0f0" : "#ffffff",
        color: "#000000",
        width: "200px",
      };
    },
  };

  return (
    <div className="modal">
      <div className="stop-overlay"></div>
      <div className="modal-medium">
        <div className="modal-heading">{props.heading}</div>
        <div className="modal-content">{props.msg}</div>
        <div className="user-form">
          <div style={{ margin: "10px", padding: "10px" }}>
            <label style={{ color: "#203570" }}>User Name</label>
            <br></br>
            <Input
              type={"text"}
              placeholder={"Type here"}
              value={user_info["user_name"] || ""}
              onChange={(e) => setUserInfo(e, "user_name")}
              hideTitleOnInput
            />
            {/* <input
              className="user_details_input"
              type="text"
              value={user_info["user_name"] || ""}
              onChange={(e) => {
                setUserInfo(e.target.value, "user_name");
              }}
            ></input> */}
          </div>
          <div
            className="user_details"
            style={{ margin: "10px", padding: "10px" }}
          >
            <label style={{ color: "#203570" }}>User Type</label>
            <br></br>
            <div style={{ marginTop: "10px" }}>
              <Select
                data={userTypeOptions}
                placeholder="Select"
                onSelect={(e) => userTypeChange(e)}
                value={userType}
                hideTitleOnInput
              />
              {/* <Select
                value={userType}
                options={userTypeOptions}
                onChange={userTypeChange}
                autoFocus={false}
                styles={selectCSSStyles}
                menuPlacement="auto"
                menuPosition="fixed"
              /> */}
            </div>
          </div>
          <div style={{ margin: "10px", padding: "10px" }}>
            <label style={{ color: "#203570" }}>Mobile Number</label>
            <br></br>
            {/* <input
              className="user_details_input"
              type="text"
              value={user_info["mobile_no"] || ""}
              onChange={(e) => {
                setUserInfo(e.target.value, "mobile_no");
              }}
            ></input> */}
            <Input
              type={"text"}
              placeholder={"Type here"}
              value={user_info["mobile_no"] || ""}
              onChange={(e) => setUserInfo(e, "mobile_no")}
              hideTitleOnInput
            />
          </div>
          <div style={{ margin: "10px", padding: "10px" }}>
            <label style={{ color: "#203570" }}>Email Id</label>
            <br></br>
            {/* <input
              className="user_details_input"
              type="email"
              value={user_info["user_id"] || ""}
              onChange={(e) => {
                setUserInfo(e.target.value, "user_id");
              }}
            ></input> */}
            <Input
              type={"text"}
              placeholder={"Type here"}
              value={user_info["user_id"] || ""}
              onChange={(e) => setUserInfo(e, "user_id")}
              hideTitleOnInput
            />
          </div>
          <div style={{ margin: "10px", padding: "10px" }}>
            <label style={{ color: "#203570" }}>Designation</label>
            <br></br>
            <Input
              type={"text"}
              placeholder={"Type here"}
              value={user_info["designation"] || ""}
              onChange={(e) => setUserInfo(e, "designation")}
              hideTitleOnInput
            />
            {/* <input
              className="user_details_input"
              type="text"
              value={user_info["designation"] || ""}
              onChange={(e) => {
                setUserInfo(e.target.value, "designation");
              }}
            ></input> */}
          </div>
        </div>
        <div className="modal-actions">
          <Button
            kind="secondary"
            compact
            onClick={() => {
              cancel();
            }}
            style={{ margin: "10px" }}
          >
            Clear
          </Button>
          <Button
            kind="primary"
            compact
            onClick={(e) => {
              submit(e);
            }}
            style={{ margin: "10px" }}
          >
            Create
          </Button>
          {/* <button
            style={{
              cursor: "pointer",
              width: "150px",
              height: "40px",
              backgroundColor: "#f0f0f0",
              border: "2px solid #289D8E",
              color: "#289D8E",
              padding: "5px",
              margin: "10px",
              fontSize: "16px",
              fontWeight: "bold",
              outline: "none",
            }}
            onClick={() => {
              cancel();
            }}
          >
            Cancel
          </button>
          <button
            style={{
              cursor: "pointer",
              width: "150px",
              height: "40px",
              backgroundColor: "#289D8E",
              border: "2px solid #289D8E",
              color: "#ffffff",
              padding: "5px",
              margin: "10px",
              fontSize: "16px",
              fontWeight: "bold",
              outline: "none",
            }}
            onClick={(e) => {
              submit(e);
            }}
          >
            Create
          </button> */}
        </div>
      </div>
    </div>
  );
}
